var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trace-query" }, [
    _c("div", { staticClass: "q-search" }, [
      _c("div", { staticClass: "s-wrap" }, [
        _c("div", { staticClass: "w-input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.code,
                expression: "code"
              }
            ],
            attrs: { type: "span", placeholder: "溯源码/防伪码" },
            domProps: { value: _vm.code },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.code = $event.target.value
              }
            }
          }),
          _c("i", { staticClass: "iconfont icon-sousuo" })
        ]),
        _c("div", { staticClass: "w-btn", on: { click: _vm.doQuery } }, [
          _c("span", [_vm._v("查询")])
        ])
      ])
    ]),
    _c("div", { staticClass: "q-main" }, [
      _vm.isOk && _vm.$_.get(_vm.info, "goodsItems.id")
        ? _c("div", { staticClass: "m-list" }, [
            _c("div", { staticClass: "l-goods-items" }, [
              _c("div", { staticClass: "gi-cover" }, [
                _c("img", { attrs: { src: _vm.thumbnailUrl } })
              ]),
              _c("div", { staticClass: "gi-content" }, [
                _c("div", { staticClass: "c-item big" }, [
                  _c("span", [_vm._v("商品名称：")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.blankGet(_vm.info, "goodsItems.goods.name"))
                    )
                  ])
                ]),
                _c("div", { staticClass: "c-item" }, [
                  _c("span", [_vm._v("商品款号：")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.blankGet(_vm.info, "goodsItems.name")))
                  ])
                ]),
                _c("div", { staticClass: "c-item" }, [
                  _c("span", [_vm._v("商品规格：")]),
                  _c("span", [_vm._v(_vm._s(_vm.goodsSpec))])
                ]),
                _c("div", { staticClass: "c-item red" }, [
                  _c("span", [_vm._v("零售价：")]),
                  _c("span", [
                    _vm._v(
                      "￥" + _vm._s(_vm.blankGet(_vm.info, "goodsItems.amount"))
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "l-timeline" },
              [
                _vm._l(
                  _vm.$_.get(_vm.info, "traceCode2LogInfoNodeList", []),
                  function(item, key) {
                    return [
                      item.type === 1
                        ? _c("div", { key: key, staticClass: "t-item" }, [
                            _vm._m(0, true),
                            _c("div", { staticClass: "i-content" }, [
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("入库人")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.stockIn.documentMaker.nickname"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("仓库入库时间")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTime(
                                        _vm.blankGet(
                                          item,
                                          "info.stockIn.createTime"
                                        )
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e(),
                      item.type === 2
                        ? _c("div", { key: key, staticClass: "t-item" }, [
                            _vm._m(1, true),
                            _c("div", { staticClass: "i-content" }, [
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("出库人")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.stockOut.documentMaker.nickname"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("出库时间")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTime(
                                        _vm.blankGet(
                                          item,
                                          "info.stockOut.createTime"
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("收件人")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.stockOut.purchanse.recipient"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("下单时间")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTime(
                                        _vm.blankGet(
                                          item,
                                          "info.stockOut.purchanse.createTime"
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("下单客服")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.stockOut.purchanse.documentMaker.nickname"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("收货地址")]),
                                _c("span", { staticStyle: { width: "70%" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.stockOut.purchanse.fullAddress"
                                      )
                                    ) +
                                      "-" +
                                      _vm._s(
                                        _vm.blankGet(
                                          item,
                                          "info.stockOut.purchanse.recipient"
                                        )
                                      ) +
                                      "/" +
                                      _vm._s(
                                        _vm.blankGet(
                                          item,
                                          "info.stockOut.purchanse.recipientMobile"
                                        )
                                      )
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e(),
                      item.type === 3
                        ? _c("div", { key: key, staticClass: "t-item" }, [
                            _vm._m(2, true),
                            _c("div", { staticClass: "i-content" }, [
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("入库人")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.operator.nickname"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("入库时间")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTime(
                                        _vm.blankGet(item, "info.createTime")
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("入库店铺")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.blankGet(item, "info.shop.name"))
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e(),
                      item.type === 4
                        ? _c("div", { key: key, staticClass: "t-item" }, [
                            _vm._m(3, true),
                            _c("div", { staticClass: "i-content" }, [
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("店铺名：")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.blankGet(item, "info.shop.name"))
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("出库人")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(
                                        item,
                                        "info.operator.nickname"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("出库时间")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTime(
                                        _vm.blankGet(item, "info.createTime")
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "c-item" }, [
                                _c("span", [_vm._v("购买会员")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.blankGet(item, "info.member.nickname")
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e()
                    ]
                  }
                )
              ],
              2
            )
          ])
        : _vm.loader == null
        ? _c("div", { staticClass: "m-no-data" }, [
            _c("img", { attrs: { src: "/image/query-no-data.png", alt: "" } }),
            _c("p", [_vm._v("暂无信息")])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "i-title" }, [
      _c("span", [_vm._v("入库信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "i-title" }, [
      _c("span", [_vm._v("出库信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "i-title" }, [
      _c("span", [_vm._v("店铺入库信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "i-title" }, [
      _c("span", [_vm._v("店铺出库信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }