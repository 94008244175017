var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "region-search" },
    [
      _c("img", {
        staticClass: "s-header",
        attrs: { src: _vm._f("prefixOssUrl")("apply/search/header-region.png") }
      }),
      _c("div", { staticClass: "s-form" }, [
        _c("div", { staticClass: "f-item" }, [
          _c("p", { staticClass: "i-label" }, [_vm._v("搜索区域")]),
          _c(
            "div",
            { staticClass: "i-input" },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入区域名称，如“北京市”",
                  "placeholder-class": "placeholder",
                  clearable: ""
                },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "f-item" }, [
          _c("p", { staticClass: "i-label" }, [_vm._v("选择区域")]),
          _c(
            "div",
            {
              staticClass: "i-select",
              on: {
                click: function($event) {
                  _vm.popupShow = true
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "s-value",
                  class: { "s-active": _vm.regionName }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.regionName
                        ? _vm.regionName
                        : " 请选择区域，如“北京市” "
                    ) + " "
                  )
                ]
              ),
              _vm.regionName
                ? _c("i", {
                    staticClass: "iconfont icon icon-guanbi",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.regionName = ""
                      }
                    }
                  })
                : _c("i", { staticClass: "iconfont icon icon-xiajiantou" })
            ]
          )
        ])
      ]),
      _c("p", { staticClass: "s-tips" }, [
        _vm._v("注：可以任意选择一种方式进行查询")
      ]),
      _c(
        "div",
        {
          staticClass: "s-btn",
          class: { active: _vm.regionName || _vm.keyword },
          on: { click: _vm.search }
        },
        [_vm._v("搜索")]
      ),
      _c(
        "van-popup",
        {
          style: { height: "60%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c("region-picker", {
            attrs: { "custom-height": "300" },
            on: {
              confirm: _vm.confirmRegion,
              cancel: function($event) {
                _vm.popupShow = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }