var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "van-swipe",
        {
          staticClass: "my-swipe",
          attrs: { autoplay: 2000, "indicator-color": "white" }
        },
        [
          _c("van-swipe-item", [
            _c("img", { attrs: { src: require("assets/swipe/swipe-1.png") } })
          ]),
          _c("van-swipe-item", [
            _c("img", { attrs: { src: require("assets/swipe/swipe-2.png") } })
          ])
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 傲雪棋定制乳胶枕 ")]),
      _c("div", { staticClass: "text1" }, [
        _vm._v(" 佛山市南海区思铭元商业有限公司 ")
      ]),
      _c("div", { staticClass: "text2" }, [_vm._v(" 全国统一零售价 998元 ")]),
      _c("div", [
        _c("span", { staticClass: "text3 mr-44" }, [_vm._v("品牌")]),
        _c("span", { staticClass: "text4" }, [_vm._v("傲雪棋")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }