var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign-form" }, [
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("申请人")]),
      _c("span", { staticClass: "i-text" }, [_vm._v(_vm._s(_vm.applierName))])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("联系电话")]),
      _c("span", { staticClass: "i-text" }, [_vm._v(_vm._s(_vm.applierMobile))])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("申请地区")]),
      _c("span", { staticClass: "i-text i-text_region" }, [
        _vm._v(_vm._s(_vm.regionAllocateName))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("上级推荐人")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.inviteMember.nickname))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("上级代理")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.superiorDistributor.nickname))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("店铺地址")]),
      _c("span", { staticClass: "i-text i-text_region" }, [
        _vm._v(_vm._s(_vm.addressFull))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("店铺面积")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.addressArea) + "㎡")
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("街道电话")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.addressContact))
      ])
    ]),
    _c("div", { staticClass: "f-upload" }, [
      _c("p", { staticClass: "u-label" }, [_vm._v("店铺租赁合同上传")]),
      _c(
        "div",
        { staticClass: "u-content" },
        [
          _c("van-uploader", {
            ref: "uploader",
            attrs: {
              multiple: "",
              accept: "image",
              "file-list": _vm.previewImgList,
              "max-count": 6,
              "after-read": _vm.afterRead
            },
            on: { delete: _vm.handleDel }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }