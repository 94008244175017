var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply-progress" },
    [
      _c("van-sticky", [
        _c(
          "div",
          { staticClass: "p-steps" },
          [_c("steps", { attrs: { active: _vm.step, steps: _vm.steps } })],
          1
        )
      ]),
      _vm.isFormShow
        ? [
            _vm.step === 0
              ? _c("deposit-form", {
                  ref: "depositForm",
                  attrs: { "p-manager": _vm.pManager }
                })
              : _vm._e(),
            _vm.step === 1
              ? _c("address-form", {
                  ref: "addressForm",
                  attrs: { "p-manager": _vm.pManager }
                })
              : _vm._e(),
            _vm.step === 2
              ? _c("sign-form", {
                  ref: "signForm",
                  attrs: { "p-manager": _vm.pManager }
                })
              : _vm._e(),
            _vm.step === 3
              ? _c("decorate-form", {
                  ref: "decorateForm",
                  attrs: { "p-manager": _vm.pManager }
                })
              : _vm._e()
          ]
        : [
            _c("a-empty", {
              attrs: {
                src: _vm._f("prefixOssUrl")(_vm.statusInfo.src),
                tips: _vm.statusInfo.tips
              }
            }),
            _vm.step === 1 && _vm.latestAuditStatus === 1
              ? _c("div", { staticClass: "p-remark" }, [
                  _c("div", { staticClass: "r-label" }, [_vm._v("待办事项:")]),
                  _c("div", { staticClass: "r-content" }, [
                    _c("span", { staticClass: "c-text" }, [
                      _vm._v("1.请联系信息部完成新店主进货。")
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "c-btn",
                        on: {
                          click: function($event) {
                            return _vm.go(
                              "/apply-approval/customer-service-contact"
                            )
                          }
                        }
                      },
                      [_vm._v("我要进货>")]
                    )
                  ])
                ])
              : _vm._e(),
            [1, 3].includes(_vm.status) && _vm.latestAuditStatus === 2
              ? [
                  _vm.remark
                    ? _c("div", { staticClass: "p-remark" }, [
                        _c("div", { staticClass: "r-label" }, [
                          _vm._v("备注:")
                        ]),
                        _c("div", { staticClass: "r-content" }, [
                          _vm._v(_vm._s(_vm.remark))
                        ])
                      ])
                    : _vm._e()
                ]
              : _vm._e()
          ],
      _vm.isFormShow
        ? _c("div", { staticClass: "p-btns" }, [
            _vm.step === 0
              ? _c(
                  "div",
                  {
                    staticClass: "b-btn b-btn_main",
                    on: { click: _vm.submitDepositForm }
                  },
                  [_vm._v("已支付，上传凭证")]
                )
              : _vm._e(),
            _vm.step === 1
              ? _c(
                  "div",
                  {
                    staticClass: "b-btn b-btn_main",
                    on: { click: _vm.submitAddresForm }
                  },
                  [_vm._v("确认提交")]
                )
              : _vm._e(),
            _vm.step === 2
              ? _c(
                  "div",
                  {
                    staticClass: "b-btn b-btn_main",
                    on: { click: _vm.submitSignForm }
                  },
                  [_vm._v("确认提交")]
                )
              : _vm._e(),
            _vm.step === 3
              ? _c(
                  "div",
                  {
                    staticClass: "b-btn b-btn_main",
                    on: { click: _vm.submitDecorateForm }
                  },
                  [_vm._v("确认提交")]
                )
              : _vm._e()
          ])
        : [
            _vm.status === 1 && _vm.latestAuditStatus === 2
              ? _c("div", { staticClass: "p-btns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "b-btn b-btn_main",
                      class: _vm.isFormShow ? "" : "fixed",
                      on: {
                        click: function($event) {
                          return _vm.reSubmit()
                        }
                      }
                    },
                    [_vm._v("重新提交")]
                  )
                ])
              : _c("div", { staticClass: "p-btns" }, [
                  _vm.step == -1
                    ? _c(
                        "div",
                        {
                          staticClass: "b-btn",
                          class: _vm.isFormShow ? "" : "fixed",
                          attrs: { type: "primary", hollow: "", size: "large" },
                          on: {
                            click: function($event) {
                              _vm.dialog.cancel = true
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm.step === 0 &&
                  !_vm.isAuditing &&
                  _vm.latestAuditStatus === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "b-btn b-btn_main",
                          class: _vm.isFormShow ? "" : "fixed",
                          on: { click: _vm.showForm }
                        },
                        [_vm._v("缴纳市场保证金")]
                      )
                    : _vm._e(),
                  [1, 2, 3].includes(_vm.step) && !_vm.isAuditing
                    ? _c(
                        "div",
                        {
                          staticClass: "b-btn b-btn_main",
                          class: _vm.isFormShow ? "" : "fixed",
                          on: { click: _vm.showForm }
                        },
                        [_vm._v("下一步")]
                      )
                    : _vm._e()
                ])
          ],
      _c("a-dialog", {
        attrs: {
          show: _vm.dialog.cancel,
          type: "error",
          title: "提示！",
          "sub-title": "您要取消该区域的申请吗？"
        },
        on: {
          close: function($event) {
            _vm.dialog.cancel = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "btns",
            fn: function() {
              return [
                _c("div", { staticClass: "a-dialog_btns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "b-btn",
                      on: {
                        click: function($event) {
                          _vm.dialog.cancel = false
                        }
                      }
                    },
                    [_vm._v("再想想")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "b-btn",
                      on: { click: _vm.confirmCancelApply }
                    },
                    [_vm._v("确定")]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }