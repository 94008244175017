var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deposit-form" },
    [
      _c("div", { staticClass: "f-item" }, [
        _c("span", { staticClass: "i-text" }, [_vm._v("申请人")]),
        _c("span", { staticClass: "i-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.pManager.info.typeId === 1
                  ? this.$_.get(
                      _vm.pManager,
                      "info.approvalSubRegionAllocateAgent.applier.nickname"
                    )
                  : this.$_.get(
                      _vm.pManager,
                      "info.approvalSubShop.applier.nickname"
                    )
              ) +
              " "
          )
        ])
      ]),
      _c("div", { staticClass: "f-item" }, [
        _c("span", { staticClass: "i-text" }, [_vm._v("联系电话")]),
        _c("span", { staticClass: "i-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.pManager.info.typeId === 1
                  ? this.$_.get(
                      _vm.pManager,
                      "info.approvalSubRegionAllocateAgent.applier.mobile"
                    )
                  : this.$_.get(
                      _vm.pManager,
                      "info.approvalSubShop.applier.mobile"
                    )
              ) +
              " "
          )
        ])
      ]),
      _c("div", { staticClass: "f-item" }, [
        _c("span", { staticClass: "i-text" }, [_vm._v("申请地区")]),
        _c("span", { staticClass: "i-text i-text_region" }, [
          _vm._v(_vm._s(this.$_.get(_vm.pManager, "info.name")))
        ])
      ]),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "f-item required",
          on: {
            click: function($event) {
              return _vm.openPopup("payWay")
            }
          }
        },
        [
          _c("span", { staticClass: "i-text" }, [_vm._v("付款方式")]),
          _c("span", { staticClass: "i-text" }, [
            _vm._v(_vm._s(_vm.selectPayWay || "请选择付款方式"))
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "f-item required",
          on: {
            click: function($event) {
              return _vm.openPopup("time")
            }
          }
        },
        [
          _c("span", { staticClass: "i-text" }, [_vm._v("付款时间")]),
          _c("span", { staticClass: "i-text" }, [
            _vm._v(_vm._s(_vm.form.depositPayTime || "请选择付款时间"))
          ])
        ]
      ),
      _c("div", { staticClass: "f-item required" }, [
        _c("span", { staticClass: "i-text" }, [_vm._v("付款人")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.depositPayer,
              expression: "form.depositPayer"
            }
          ],
          staticClass: "i-input",
          attrs: { placeholder: "请填写付款人姓名" },
          domProps: { value: _vm.form.depositPayer },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "depositPayer", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "f-upload" }, [
        _c("p", { staticClass: "u-label" }, [_vm._v("上传凭证")]),
        _c(
          "div",
          { staticClass: "u-content" },
          [
            _c("div", { staticClass: "c-tip" }, [
              _vm._v("注：请正确上传转账截图")
            ]),
            _c("van-uploader", {
              ref: "uploader",
              attrs: {
                multiple: "",
                accept: "image",
                "file-list": _vm.previewImgList,
                "max-count": 6,
                "after-read": _vm.afterRead
              },
              on: { delete: _vm.handleDel }
            })
          ],
          1
        )
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", round: "" },
          on: {
            close: function($event) {
              _vm.popupShow = false
            }
          },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _vm.curPopup == "payWay"
            ? _c("van-picker", {
                attrs: { columns: _vm.columns, "show-toolbar": "" },
                on: {
                  confirm: _vm.handlePayWayConfirm,
                  cancel: function($event) {
                    _vm.popupShow = false
                  }
                }
              })
            : _vm._e(),
          _vm.curPopup == "time"
            ? _c("van-datetime-picker", {
                attrs: { type: "date", "min-date": _vm.minDate },
                on: {
                  confirm: _vm.handleTimeConfirm,
                  cancel: function($event) {
                    _vm.popupShow = false
                  }
                },
                model: {
                  value: _vm.curDate,
                  callback: function($$v) {
                    _vm.curDate = $$v
                  },
                  expression: "curDate"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("市场保证金")]),
      _c("span", { staticClass: "i-text" }, [_vm._v("5000.00元")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }