/*
 * @Author: 码上talk|RC
 * @Date: 2021-04-26 21:12:46
 * @LastEditTime: 2022-01-10 10:01:41
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/mixins/type/filter.js
 * Just do what I think it is right
 */
import Vue from 'vue';

import dayjs from 'dayjs';

import { appConfig } from '@/config/app'

Vue.mixin({
  filters: {
    prefixOssUrl (val) {
      return appConfig.staticUrl + val
    },
    timeFormat (d, f = 'YYYY-MM-DD HH:mm') {
      if (!d) {
        return '2019-01-01 00:00';
      }
      return dayjs(d).format(f);
    }
  }
});
