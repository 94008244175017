<template>
  <div class="decorate-form form">
    <div class="f-item">
      <span class="i-text">申请人</span>
      <span class="i-text">{{ applierName }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">联系电话</span>
      <span class="i-text">{{ applierMobile }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">申请地区</span>
      <span class="i-text i-text_region">{{ regionAllocateName }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">上级推荐人</span>
      <span class="i-text">{{ inviteMember.nickname }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">上级代理</span>
      <span class="i-text">{{ superiorDistributor.nickname }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">店铺地址</span>
      <span class="i-text i-text_region">{{ addressFull }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">店铺面积</span>
      <span class="i-text">{{ addressArea }}㎡</span>
    </div>
    <div class="f-item">
      <span class="i-text">街道电话</span>
      <span class="i-text">{{ addressContact }}</span>
    </div>
    <div class="f-upload">
      <p class="u-label">店铺租赁合同</p>
      <div class="u-content">
        <file-previewer :file-list="tenancyImgList"></file-previewer>
      </div>
    </div>
    <div class="f-upload">
      <p class="u-label">店铺装修照片上传</p>
      <div class="u-content">
        <van-uploader ref="uploader" :file-list="previewImgList" multiple preview-full-image accept="image"
          :max-count="6" :after-read="afterRead" @delete="handleDel"></van-uploader>
      </div>
    </div>
  </div>
</template>

<script>
  import filePreviewer from '@/components/file-previewer';
  import ApplyMixin from '../@js/apply-mixin'
  import {
    model
  } from '@/model';

  const {
    Request
  } = model;
  export default {
    components:{
      filePreviewer
    },
    mixins: [ApplyMixin],
    props: {
      pManager: Object
    },
    data() {
      return {
        request: new Request(),
        imgList: [],
        previewImgList: []
      }
    },
    methods: {
      ossRequest(e) {
        this.request.do('open', 'oss', 'authorize', {
          params: {},
          data: {}
        }).then((res) => {
          if (res.status) return this.upload(res.data, e.file)
        }).then((res) => {
          if (res.status) {
            this.imgList.push({
              _file: e.file,
              info: {
                name: e.file.name,
                size: e.file.size,
              },
              oss: res.data.data,
            })
            this.previewImgList.push(e)
          } else {
            throw new Error()
          }
        }).catch(() => {
          this.$toast('上传图片失败')
        })
      },
      afterRead(e) {
        if (Array.isArray(e)) {
          e.forEach((f) => {
            this.ossRequest(f);
          })
        } else {
          this.ossRequest(e);
        }
      },
      handleDel(event, detail) {
        this.imgList.splice(detail.index, 1)
        this.previewImgList.splice(detail.index, 1)
      },
    }
  }
</script>

<style lang="less">
  .decorate-form {
    padding: 0 30px;

    .f-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-top: 20px;
      border-bottom: 1px solid #ccc;

      .i-text {
        font-size: 32px;
        font-weight: 500;
        color: #666666;

        &_region {
          width: 500px;
          text-align: right;
        }
      }

      .i-input {
        border: none;
        text-align: right;
        font-size: 32px;
      }
    }

    .f-upload {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 40px;

      .u-label {
        position: relative;
        font-size: 32px;

        &::before {
          content: '*';
          position: absolute;
          top: 0;
          left: -20px;
          font-size: 40px;
          font-weight: bold;
          color: #FF0000;
        }
      }

    }
  }
</style>