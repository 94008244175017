var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-apply-search" },
    [
      _c("img", {
        staticClass: "s-header",
        attrs: { src: _vm._f("prefixOssUrl")("apply/search/header-shop.png") }
      }),
      _c("div", { staticClass: "s-form" }, [
        _c("div", { staticClass: "f-item" }, [
          _c("span", { staticClass: "i-label" }, [_vm._v("搜索区域")]),
          _c(
            "div",
            {
              staticClass: "i-select",
              on: {
                click: function($event) {
                  return _vm.openPopup("region")
                }
              }
            },
            [
              _c("input", {
                attrs: { placeholder: "请选择区域", disabled: "" },
                domProps: { value: _vm.selectedFullName }
              }),
              _vm.selectedFullName
                ? _c("i", {
                    staticClass: "iconfont icon-guanbi",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clear.apply(null, arguments)
                      }
                    }
                  })
                : _c("i", { staticClass: "iconfont icon-xiajiantou" })
            ]
          )
        ]),
        _c("div", { staticClass: "f-item" }, [
          _c("span", { staticClass: "i-label" }, [_vm._v("区域用有人信息")]),
          _c(
            "div",
            { staticClass: "i-info" },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  placeholder: "拥有人姓名",
                  value: _vm.regionOwner.name
                },
                scopedSlots: _vm._u([
                  {
                    key: "prefix",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "input-prefix" }, [
                          _vm._v("拥有人姓名：")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("a-input", {
                attrs: {
                  disabled: "",
                  placeholder: "拥有人电话",
                  value: _vm.regionOwner.mobile
                },
                scopedSlots: _vm._u([
                  {
                    key: "prefix",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "input-prefix" }, [
                          _vm._v("拥有人电话：")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          staticClass: "s-btn",
          class: _vm.isButtonActive ? "active" : "",
          on: { click: _vm.applyShop }
        },
        [_vm._v("申请店铺")]
      ),
      _c(
        "van-popup",
        {
          style: { height: "60%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _vm.curPopup === "region"
            ? _c("region-picker", {
                attrs: { "custom-height": "300" },
                on: {
                  confirm: _vm.confirmRegion,
                  cancel: function($event) {
                    _vm.popupShow = false
                  }
                }
              })
            : _vm._e(),
          _vm.curPopup === "block"
            ? _c("block-picker", {
                ref: "blockPicker",
                attrs: { "cancel-button-text": "返回" },
                on: {
                  confirm: _vm.confirmBlock,
                  cancel: function($event) {
                    _vm.curPopup = "region"
                  },
                  "no-data": _vm.handleNoData
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("a-dialog", {
        attrs: {
          show: _vm.dialog.applyRes,
          title: _vm.applyRes.title,
          "sub-title": _vm.applyRes.subTitle,
          type: _vm.applyStatus
        },
        on: {
          close: function($event) {
            _vm.dialog.applyRes = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "btns",
            fn: function() {
              return [
                _vm.dialog.btnStatus === 1
                  ? _c(
                      "a-btn",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.dialog.applyRes = false
                          }
                        }
                      },
                      [_vm._v("重新选择")]
                    )
                  : _c(
                      "a-btn",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.dialog.applyRes = false
                          }
                        }
                      },
                      [_vm._v("确认")]
                    )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }