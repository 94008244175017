var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "region-search-result" },
    [
      _c("van-sticky", [
        _c("div", { staticClass: "status-filter" }, [
          _c(
            "div",
            {
              staticClass: "status-item",
              class: { active: _vm.form.status == 1 },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.setStatus(1)
                }
              }
            },
            [
              _c("img", {
                staticClass: "status-item-icon",
                attrs: {
                  src: _vm._f("prefixOssUrl")(
                    "apply/search/result/can-apply.png"
                  )
                }
              }),
              _c("p", { staticClass: "status-item-text" }, [_vm._v("可申请")])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "status-item",
              class: { active: _vm.form.status == 2 },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.setStatus(2)
                }
              }
            },
            [
              _c("img", {
                staticClass: "status-item-icon",
                attrs: {
                  src: _vm._f("prefixOssUrl")(
                    "apply/search/result/searching.png"
                  )
                }
              }),
              _c("p", { staticClass: "status-item-text" }, [_vm._v("寻找中")])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "status-item",
              class: { active: _vm.form.status == 3 },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.setStatus(3)
                }
              }
            },
            [
              _c("img", {
                staticClass: "status-item-icon",
                attrs: {
                  src: _vm._f("prefixOssUrl")(
                    "apply/search/result/decorating.png"
                  )
                }
              }),
              _c("p", { staticClass: "status-item-text" }, [_vm._v("装修中")])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "status-item",
              class: { active: _vm.form.status == 4 },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.setStatus(4)
                }
              }
            },
            [
              _c("img", {
                staticClass: "status-item-icon",
                attrs: {
                  src: _vm._f("prefixOssUrl")("apply/search/result/opening.png")
                }
              }),
              _c("p", { staticClass: "status-item-text" }, [_vm._v("已开业")])
            ]
          )
        ]),
        _c("div", { staticClass: "table-header" }, [
          _c(
            "div",
            { staticClass: "table-column", staticStyle: { width: "100px" } },
            [_vm._v("省")]
          ),
          _c(
            "div",
            { staticClass: "table-column", staticStyle: { width: "100px" } },
            [_vm._v("市")]
          ),
          _c("div", { staticClass: "table-column" }, [_vm._v("区")]),
          _c("div", { staticClass: "table-column column-btn" }, [
            _vm._v("区域范围")
          ]),
          _c(
            "div",
            {
              staticClass: "table-column column-btn",
              staticStyle: { "text-align": "right" }
            },
            [_vm._v("操作")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "table-body" },
        [
          _c(
            "van-pull-refresh",
            {
              attrs: { "success-text": "刷新成功" },
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.regionList, function(d, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "table-row",
                      class: {
                        apply: d.status == 1,
                        searching: d.status == 2,
                        decorating: d.status == 3,
                        opening: d.status == 4
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "table-column column-p",
                          staticStyle: { width: "100px" }
                        },
                        [_vm._v(_vm._s(d.provinceName || "--"))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "table-column column-c",
                          staticStyle: { width: "100px" }
                        },
                        [_vm._v(_vm._s(d.cityName || "--"))]
                      ),
                      _c("div", { staticClass: "table-column column-d" }, [
                        _vm._v(_vm._s(d.districtName || "--"))
                      ]),
                      _c("div", { staticClass: "table-column column-n" }, [
                        _vm._v(_vm._s(d.name || "--"))
                      ]),
                      _c("div", { staticClass: "table-column column-btn" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function($event) {
                                return _vm.openDetail(d)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": "", closeable: "" },
          on: {
            close: function($event) {
              _vm.popupShow = false
            }
          },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c("div", { staticClass: "area-popup" }, [
            _c("div", { staticClass: "p-title" }, [_vm._v("街道")]),
            _c(
              "div",
              { staticClass: "p-area" },
              _vm._l(_vm.remarkList, function(a, i) {
                return _c("div", { key: i, staticClass: "area-item" }, [
                  _vm._v(_vm._s(a))
                ])
              }),
              0
            ),
            _vm.form.status === 1
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "p-btn"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "b-item",
                        on: {
                          click: function($event) {
                            return _vm.apply(2)
                          }
                        }
                      },
                      [_vm._v("他人区域申请")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "b-item",
                        on: {
                          click: function($event) {
                            return _vm.apply(1)
                          }
                        }
                      },
                      [_vm._v("个人区域申请")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }