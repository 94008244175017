var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "_500" }, [
    _c("div", { staticClass: "_5-content" }, [
      _c("img", {
        attrs: { src: _vm._f("prefixOssUrl")("error_500.png"), alt: "" }
      }),
      _c("p", [_vm._v("服务器错误，请联系技术部")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }