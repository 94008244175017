var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "popup",
            on: {
              click: function($event) {
                _vm.visible = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "p-content",
                class: _vm.contentClass,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _vm.slotVisible
                  ? _vm._t("default")
                  : _c("render", { attrs: { tpl: _vm.tpl } })
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }