var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "axq-empty" },
    [
      _c("img", { staticClass: "axq-empty-img", attrs: { src: _vm.src } }),
      _vm._l(_vm.tips, function(t, i) {
        return _c("span", { key: i, staticClass: "axq-empty-text" }, [
          _vm._v(_vm._s(t))
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }