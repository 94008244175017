<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 11:02:11
 * @LastEditTime: 2022-01-15 11:53:14
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/approval/@fragment/address-form.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="address-form">
    <div class="f-item">
      <span class="i-text">申请人</span>
      <span class="i-text">{{ applierName }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">联系电话</span>
      <span class="i-text">{{ applierMobile }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">申请地区</span>
      <span class="i-text i-text_region">{{ regionAllocateName }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">上级推荐人</span>
      <span class="i-text">{{ inviteMember.nickname }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">上级代理</span>
      <span class="i-text">{{ superiorDistributor.nickname }}</span>
    </div>
    <div class="f-item required">
      <span class="i-text">店铺地址</span>
      <input class="i-input" v-model="form.addressFull" placeholder="请输入店铺地址" type="textarea" />
    </div>
    <div class="f-item">
      <span class="i-text">店铺面积</span>
      <div class="i-input">
        <input v-model="form.addressArea" type="number" placeholder="0" />
        <span class="i-unit">㎡</span>
      </div>
    </div>
    <div class="f-item required">
      <span class="i-text">街道电话</span>
      <input class="i-input" v-model="form.addressContact" placeholder="请输入店铺所属街道电话" />
    </div>
  </div>
</template>

<script>
  import ProgressManager from '../@js/ProgressManager'
  import ApplyMixin from '../@js/apply-mixin'

  export default {
    mixins: [ApplyMixin],
    props: {
      pManager: Object
    },
    data() {
      return {
        manager: new ProgressManager(),
        form: {
          addressFull: '',
          addressArea: '',
          addressContact: ''
        }
      }
    },
    created() {
      this.manager.info = this.pManager.info
    },
  }
</script>

<style lang="less" scoped>
  .address-form {
    padding: 0 30px;

    .f-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-top: 20px;
      border-bottom: 1px solid #ccc;

      .i-text {
        font-size: 32px;
        font-weight: 500;
        color: #666666;

        &_region {
          width: 500px;
          text-align: right;
        }
      }

      .i-input {
        border: none;
        text-align: right;
        font-size: 32px;

        input {
          border: none;
          text-align: right;
        }
      }
    }
  }
</style>