<!--
 * @Author: 码上talk|RC/3189482282@qq.com
 * @Date: 2022-01-08 08:24:49
 * @LastEditTime: 2022-01-10 13:55:49
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/@fragment/region-card.vue
-->
<template>
  <div class="region-card" @click="handleClick()">
    <span class="region-card-title">{{ title }}</span>
    <img class="region-card-bg" :src="imgUrl" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    imgUrl: String
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.region-card {
  position: relative;
  padding: 35px 39px;
  width: 330px;
  height: 330px;
  box-sizing: border-box;
  &:active {
    filter: brightness(0.8);
  }
  &-title {
    position: relative;
    z-index: 1;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
  }
  &-bg {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 330px;
    height: 330px;
  }
}
</style>