var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-previewer" }, [
    _c(
      "div",
      { staticClass: "l-list" },
      _vm._l(_vm.fileList, function(item, key) {
        return _c("div", { key: key, staticClass: "l-item" }, [
          _c("img", {
            attrs: { src: item.oss.url, alt: "" },
            on: {
              click: function($event) {
                return _vm.showPopup(item)
              }
            }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }