<template>
  <div class="shop-apply">
    <div class="a-form">
      <div class="f-title">申请人信息</div>
      <div class="f-item">
        <span class="i-text">申请人</span>
        <span class="i-text">{{ $_.get(info,'nickname') }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">联系电话</span>
        <span class="i-text">{{ $_.get(info,'mobile') }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">申请地区</span>
        <span class="i-text i-text_region">{{ blockInfo.regionFullName }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">上级推荐人</span>
        <span class="i-text">{{ $_.get(info,'parent.nickname')  }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">上级代理</span>
        <span class="i-text">{{ $_.get(info,'distributor.nickname') }}</span>
      </div>
      <div class="f-item" @click="popupShow=true">
        <span class="i-text">团队领导人</span>
        <div>
          <span class="i-text">{{ selectedLeader.name ? selectedLeader.name : '请选择' }}</span>
          <span v-if="!selectedLeader.name" class="iconfont icon-xiajiantou"></span>
        </div>
      </div>
      <template v-if="Number(blockInfo.isEnableRegionApply) === 1">
        <div class="f-title">授权人信息</div>
        <div class="f-item">
          <span class="i-text">授权人</span>
          <span class="i-text">{{ blockInfo.name }}</span>
        </div>
        <div class="f-item">
          <span class="i-text">联系电话</span>
          <span class="i-text">{{ blockInfo.mobile }}</span>
        </div>
        <div class="f-title f-title_required">授权人同意书</div>
        <van-uploader ref="uploader" multiple accept="image" :file-list="previewImgList"  :after-read="afterRead" @delete="handleDel" :multiple="false"></van-uploader>
      </template>
    </div>
    <div class="a-bottom">
      <button class="b-btn" @click="submitApply">提交申请</button>
    </div>

    <van-popup v-model="popupShow" position="bottom" :style="{ height: '50%' }">
      <group-leader-picker @confirm="confirmLeader" @cancel="popupShow=false">
      </group-leader-picker>
    </van-popup>

    <a-dialog :show="dialog.applyRes" :title="applyRes.title" :sub-title="applyRes.subTitle" :type="applyRes.type" @close="handelAdialogClose">
      <template v-slot:btns>
        <template v-if="applyRes.type == 'error'">
          <a-btn type="primary" hollow @click="handelAdialogClose">返回</a-btn>
          <a-btn type="primary" @click="back()">重新选择</a-btn>
        </template>
        <template v-else>
          <a-btn type="primary" @click="handelAdialogClose">返回</a-btn>
        </template>
      </template>
    </a-dialog>

  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import aBtn from '@/components/btn';
import aDialog from '@/components/dialog';
import GroupLeaderPicker from '@/components/picker/group-leader';
import {
  model
} from '@/model';

const {
  Oss,
  Approval,
} = model.collection

const ApprovalType = {
  REGION: 1,
  SHOP: 2
}

export default {
  components: {
    aBtn,
    aDialog,
    GroupLeaderPicker
  },
  metaInfo () {
    return {
      title: '店铺授权申请'
    }
  },
  data () {
    return {
      blockInfo: {},
      popupShow: false,
      selectedLeader: {},
      dialog: {
        applyRes: false
      },
      applyRes: {
        type: 'success',
        title: '抱歉！提交失败。',
        subTitle: '该区域已被申请，请重新选择。'
      },
      previewImgList: [],
      imgList: [],
      btnLoading: false
    }
  },
  computed: {
    ...mapState('user', ['info'])
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    ossRequest (e) {
      Oss.sendApi('authorize', {
        params: {},
        data: {
          insertDb: 0,
          dir: ''
        }
      }).then((res) => {
        if (res.status) {
          return this.upload(res.data, e.file)
        }
      }).then((res) => {
        if (res.status) {
          this.previewImgList.push(e)
          this.imgList.push({
            _file: e.file,
            info: {
              name: e.file.name,
              size: e.file.size
            },
            oss: res.data.data
          })
        } else {
          throw new Error()
        }
      }).catch((e) => {
        this.$toast('上传图片失败')
      })
    },
    afterRead (e) {
      if (Array.isArray(e)) {
        e.forEach((f) => {
          this.ossRequest(f);
        })
      } else {
        this.ossRequest(e);
      }
    },
    handleDel (file, detail) {
      this.imgList.splice(detail.index, 1)
      this.previewImgList.splice(detail.index, 1)
    },
    confirmLeader (e) {
      this.selectedLeader.id = e.id;
      this.selectedLeader.name = e.text;
      this.popupShow = false;
    },
    showRes (type = 'success', {
      title,
      subTitle
    } = {}) {
      if (type == 'success') {
        this.applyRes = {
          type: 'success',
          title: '恭喜您！提交成功。',
          subTitle: '可进入“我的申请”查看进度详情。'
        }
      } else if (type == 'error') {
        this.applyRes = {
          type: 'error',
          title: title ? title : '抱歉！提交失败。',
          subTitle: subTitle ? subTitle : '该区域已被申请,请重新选择'
        }
      }
      this.dialog.applyRes = true
    },
    handelAdialogClose () {
      if (this.applyRes.type == 'success') {
        this.back()
      } else {
        this.dialog.applyRes = false
      }
    },
    submitApply () {
      if (!this.selectedLeader.id) {
        return this.$toast('请选择团队领导人！')
      }
      if (Number(this.blockInfo.isEnableRegionApply) === 1 && this.previewImgList.length === 0) {
        return this.$toast('请上传授权人同意书！')
      }
      this.$dialog.confirm({
        title: '确认提交申请'
      }).then(() => {
        this.btnLoading = true
        let form = {
          typeId: ApprovalType.SHOP,
          name: this.blockInfo.regionFullName,
          approvalSubShop: {
            type: ApprovalType.MYSELF,
            applierId: this.info.id,
            creatorId: this.info.id,
            regionAllocateId: this.blockInfo.allocateId,
            inviteMemberId: this.info.parent.id,
            superiorDistributorId: this.info.distributor.id,
            topDistributorId: this.selectedLeader.id,
            fileAuthorizeAgreement: JSON.stringify(this.imgList)
          }
        }
        Approval.sendApi('add', {
          params: {},
          data: form
        }).then((res) => {
          if (res.status) {
            this.showRes()
          } else {
            this.showRes('error')
          }
        }).finally(() => {
          this.btnLoading = false
        })
      }).catch(() => { })
    }
  },
  created () {
    this.blockInfo = this.$route.query;
    this.getUserInfo();
  }
}
</script>

<style lang="less" scoped>
.shop-apply {
  padding: 0 30px;
  background: #fff;

  .a-form {
    margin-bottom: 180px;
    .f-title {
      position: relative;
      margin: 30px 0;
      font-size: 35px;
      font-weight: bold;
      color: #333;
    }

    .f-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-top: 20px;
      border-bottom: 1px solid #ccc;

      .i-text {
        font-size: 32px;
        font-weight: 500;
        color: #666666;

        &_region {
          width: 500px;
          text-align: right;
        }
      }

      .i-input {
        border: none;
        text-align: right;
        font-size: 32px;
      }
    }
  }

  .a-bottom {
    position: fixed;
    bottom: 0;
    z-index: 99;
    height: 150px;
    line-height: 150px;
    background: #fff;

    .b-btn {
      width: 690px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      color: #fff;
      background: #db187c;
      border: 1px solid #fff;
      border-radius: 10px;
    }
  }

  a-dialog {
    a-btn:not(:first-of-type) {
      margin-left: 20px;
    }

    .axq-btn {
      width: 200px;
      height: 60px;
      border-radius: 30px;
      line-height: 60px;
      border: 2px solid #db187c;
    }
  }
}
</style>