var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-apply" },
    [
      _c(
        "div",
        { staticClass: "a-form" },
        [
          _c("div", { staticClass: "f-title" }, [_vm._v("申请人信息")]),
          _c("div", { staticClass: "f-item" }, [
            _c("span", { staticClass: "i-text" }, [_vm._v("申请人")]),
            _c("span", { staticClass: "i-text" }, [
              _vm._v(_vm._s(_vm.$_.get(_vm.info, "nickname")))
            ])
          ]),
          _c("div", { staticClass: "f-item" }, [
            _c("span", { staticClass: "i-text" }, [_vm._v("联系电话")]),
            _c("span", { staticClass: "i-text" }, [
              _vm._v(_vm._s(_vm.$_.get(_vm.info, "mobile")))
            ])
          ]),
          _c("div", { staticClass: "f-item" }, [
            _c("span", { staticClass: "i-text" }, [_vm._v("申请地区")]),
            _c("span", { staticClass: "i-text i-text_region" }, [
              _vm._v(_vm._s(_vm.blockInfo.regionFullName))
            ])
          ]),
          _c("div", { staticClass: "f-item" }, [
            _c("span", { staticClass: "i-text" }, [_vm._v("上级推荐人")]),
            _c("span", { staticClass: "i-text" }, [
              _vm._v(_vm._s(_vm.$_.get(_vm.info, "parent.nickname")))
            ])
          ]),
          _c("div", { staticClass: "f-item" }, [
            _c("span", { staticClass: "i-text" }, [_vm._v("上级代理")]),
            _c("span", { staticClass: "i-text" }, [
              _vm._v(_vm._s(_vm.$_.get(_vm.info, "distributor.nickname")))
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "f-item",
              on: {
                click: function($event) {
                  _vm.popupShow = true
                }
              }
            },
            [
              _c("span", { staticClass: "i-text" }, [_vm._v("团队领导人")]),
              _c("div", [
                _c("span", { staticClass: "i-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedLeader.name
                        ? _vm.selectedLeader.name
                        : "请选择"
                    )
                  )
                ]),
                !_vm.selectedLeader.name
                  ? _c("span", { staticClass: "iconfont icon-xiajiantou" })
                  : _vm._e()
              ])
            ]
          ),
          Number(_vm.blockInfo.isEnableRegionApply) === 1
            ? [
                _c("div", { staticClass: "f-title" }, [_vm._v("授权人信息")]),
                _c("div", { staticClass: "f-item" }, [
                  _c("span", { staticClass: "i-text" }, [_vm._v("授权人")]),
                  _c("span", { staticClass: "i-text" }, [
                    _vm._v(_vm._s(_vm.blockInfo.name))
                  ])
                ]),
                _c("div", { staticClass: "f-item" }, [
                  _c("span", { staticClass: "i-text" }, [_vm._v("联系电话")]),
                  _c("span", { staticClass: "i-text" }, [
                    _vm._v(_vm._s(_vm.blockInfo.mobile))
                  ])
                ]),
                _c("div", { staticClass: "f-title f-title_required" }, [
                  _vm._v("授权人同意书")
                ]),
                _c("van-uploader", {
                  ref: "uploader",
                  attrs: {
                    multiple: "",
                    accept: "image",
                    "file-list": _vm.previewImgList,
                    "after-read": _vm.afterRead,
                    multiple: false
                  },
                  on: { delete: _vm.handleDel }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "a-bottom" }, [
        _c("button", { staticClass: "b-btn", on: { click: _vm.submitApply } }, [
          _vm._v("提交申请")
        ])
      ]),
      _c(
        "van-popup",
        {
          style: { height: "50%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c("group-leader-picker", {
            on: {
              confirm: _vm.confirmLeader,
              cancel: function($event) {
                _vm.popupShow = false
              }
            }
          })
        ],
        1
      ),
      _c("a-dialog", {
        attrs: {
          show: _vm.dialog.applyRes,
          title: _vm.applyRes.title,
          "sub-title": _vm.applyRes.subTitle,
          type: _vm.applyRes.type
        },
        on: { close: _vm.handelAdialogClose },
        scopedSlots: _vm._u([
          {
            key: "btns",
            fn: function() {
              return [
                _vm.applyRes.type == "error"
                  ? [
                      _c(
                        "a-btn",
                        {
                          attrs: { type: "primary", hollow: "" },
                          on: { click: _vm.handelAdialogClose }
                        },
                        [_vm._v("返回")]
                      ),
                      _c(
                        "a-btn",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.back()
                            }
                          }
                        },
                        [_vm._v("重新选择")]
                      )
                    ]
                  : [
                      _c(
                        "a-btn",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handelAdialogClose }
                        },
                        [_vm._v("返回")]
                      )
                    ]
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }