<template>
    <div class="page">
        <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
            <van-swipe-item>
                <img
                    src="~assets/swipe/swipe-1.png"
                />
            </van-swipe-item>
            <van-swipe-item>
                 <img
                    src="~assets/swipe/swipe-2.png"
                />
            </van-swipe-item>
        </van-swipe>
        <div class="container">
            <div class="title">
                傲雪棋定制乳胶枕
            </div>
            <div class="text1">
                佛山市南海区思铭元商业有限公司
            </div>
             <div class="text2">
                全国统一零售价 998元
            </div>
            <div>
                <span class="text3 mr-44">品牌</span>
                <span class="text4">傲雪棋</span>
            </div>
        </div>
    </div>
</template>

<script scoped>
    export default {
        data() {
            return {
                goodsId: null
            }
        },
        mounted () {
            this.goodsId = this.$route.query.goodsId;
        },
    }
</script>

<style lang="less" scoped>
.page {
    .my-swipe .van-swipe-item {
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .container {
        padding: 26px 26px 0 26px;
        .title {
            font-size: 39px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-bottom: 17px;
        }
        .text1 {
            font-size: 29px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #B3B3B3;
            line-height: 20px;
            margin-bottom: 45px;
        }
        .text2 {
            font-size: 29px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 20px;
            margin-bottom: 45px;
        }
        .text3 {
            font-size: 29px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #B3B3B3;
            line-height: 20px;
            margin-right: 44px;
        }
        .text4 {
            font-size: 29px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
        }
       
    }
    
}

/deep/.van-icon {
    color:  #000;
}

</style>