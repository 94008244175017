var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-picker", {
    ref: "picker",
    staticClass: "group-leader",
    attrs: {
      title: "选择团队领导人",
      "default-index": _vm.defaultIndex,
      "show-toolbar": "",
      columns: _vm.columns
    },
    on: {
      change: _vm.handleChange,
      confirm: _vm.handleConfirm,
      cancel: function($event) {
        return _vm.$emit("cancel")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }