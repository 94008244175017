/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-05 08:09:29
 * @LastEditTime: 2022-01-15 16:29:11
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/mixins/type/utils.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import Vue from 'vue';
import dayjs from 'dayjs';
import axios from 'axios';
const _ = require('lodash');

Vue.mixin({
  methods: {
    replaceJsonTime(t) {
      console.log("----",t)
      return t && t.length > 10 ? t.replace(/[TZ]/g ,' ') : '-'
    },
    getDayjsObj (v) {
      return dayjs(v);
    },
    blankGet (obj, s) {
      return _.get(obj, s) || '/';
    },
    isTraceCode (s) {
      return s.length === 8 && /^[A-Z][0-9]*/.test(s);
    },
    isTraceCaptcha (s) {
      return s.length === 32;
    },
    isTraceCodeV1 (s) {
      return s.indexOf('aoxueqio2o.com') > -1;
    },
    developing () {
      return this.$toast('功能在开发中，敬请期待！');
    },
    _generateKey () {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      const maxPos = chars.length;
      let name = '';
      for (let i = 0; i < 7; i++) {
        name += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return `${name}${new Date().getTime()}`;
    },
    upload (authorize, file) {
      return new Promise((resolve) => {
        const formData = new FormData();
        formData.append('policy', authorize.policy);
        formData.append('OSSAccessKeyId', authorize.accessKey);
        formData.append('success_action_status', '200');
        formData.append('callback', authorize.callback);
        formData.append('signature', authorize.signature);
        formData.append('key', authorize.dir + this._generateKey());
        formData.append('dir', authorize.dir);
        formData.append('file', file);
        axios({
          url: '//' + authorize.host,
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          resolve(res);
        });
      });
    },
    copy (v) {
      let oInput = document.createElement('input');
      oInput.value = v;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy"); 
      this.$toast.success('复制成功');
      oInput.remove()
    }
  }
});
