/*
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 16:28:05
 * @LastEditTime: 2021-06-07 16:29:04
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /m/src/mixins/index.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */

import './type/filter';
import './type/navigator';
import './type/utils';
