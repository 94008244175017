function unixTime(unix, nano) {
    return new Date(unix * 1000 + (nano || 0));
}


export function objectToMap(obj) {
    const map = new Map();
    Object.keys(obj).forEach(key => map.set(key, obj[key]));
    return map;
}

// 格式化时间
export function formatDate(d, fmt) {
    if (typeof (d) == "number") d = unixTime(d, 0);
    if (typeof (d) == "string") d = new Date(d);
    fmt = fmt || DefaultTimeFormat;
    let o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours() % 12 == 0 ? 12 : d.getHours() % 12, //小时
        "H+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        "S": d.getMilliseconds() //毫秒
    };
    let week = ["/u65e5", "/u4e00", "/u4e8c", "/u4e09", "/u56db", "/u4e94", "/u516d"];
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1)
            ? (RegExp.$1.length > 2 ? "/u661f/u671f" : "/u5468")
            : "") + week[d.getDay()]);
    }

    const od = objectToMap(o);

    for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1)
                ? (od.get(k)) : (("00" + od.get(k)).substr(("" + od.get(k)).length)));
        }
    }
    return fmt;
}

export function replaceJsonTime(t) {
    return t && t.length > 10 ? t.replace(/[TZ]/g, ' ') : '-';
}