<template>
  <div :class="classes">
    <div class="axq-input__prefix">
      <slot name="prefix"></slot>
    </div>
    <template v-if="!textarea">
      <div v-if="password" class="axq-input__inner" :class="{'axq-input__inner--visible': isPasswdShow === true}">
        <input type="text" :placeholder="placeholder" :placeholder-class="phClass" :value="value" @input="handleInput"
          @blur="replaceInput" />
        <span v-if="isPasswdShow" @input="handleInput">{{value.replace(/\S/g, '*')}}</span>
      </div>
      <input v-else class="axq-input__inner" :value="value" :type="type" :placeholder="placeholder"
        :placeholder-class="phClass" :placeholder-style="placeholderStyle" :disabled="disabled" @input="handleInput"
        @blur="replaceInput" />
    </template>
    <template v-else>
      <textarea class="axq-input__inner textarea" :autoHeight="autoHeight" :value="value" :placeholder="placeholder"
        :placeholder-class="phClass" :placeholder-style="placeholderStyle" :disabled="disabled" :maxlength="maxlength"
        @input="handleInput" @blur="replaceInput"></textarea>
    </template>
    <div class="axq-input__suffix">
      <slot name="suffix"></slot>
    </div>
    <i v-if="clearable && value" class="iconfont icon-guanbi" @click.stop="clear"></i>
  </div>
</template>

<script>
  export default {
    name: 'AInput',
    props: {
      value: [String, Number],
      password: Boolean,
      type: String,
      placeholder: String,
      placeholderClass: String,
      placeholderStyle: String,
      disabled: Boolean,
      trim: Boolean,
      clearable: Boolean,
      textarea: Boolean,
      maxlength: {
        type: Number,
        default: 140
      },
      autoHeight: Boolean,
      isPasswdShow: Boolean
    },
    data() {
      return {
        isShow: false
      }
    },
    computed: {
      classes() {
        let res = 'axq-input'
        if (this.clearable) res += ' axq-input--clearable'
        return res
      },
      phClass() {
        return 'axq-input__placeholder ' + (this.placeholderClass || '')
      }
    },
    methods: {
      handleInput(e) {
        let res = e.target.value
        if (res && this.trim) {
          res = res.replace(/\s/g, '')
        }
        this.$emit('input', res)
        return res
      },
      replaceInput(e) {
        this.$emit('blur', e)
      },
      clear() {
        this.$emit('input', '')
      }
    }
  }
</script>

<style lang="less">
  .axq-input {
    display: flex;
    align-items: center;

    .axq-input__inner {
      position: relative;
      flex: 1;
      padding-left: 20px;
      min-height: 90px;
      font-size: 30px;
      color: #333;
      border:none;

      input {
        margin-top: 20px;
      }

      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &--visible {
        input {
          color: RGBA(0, 0, 0, 0);
        }
      }

      &.textarea {
        padding-top: 28px;
        width: auto;
        min-height: 90px;
        line-height: 90px;
      }
    }

    .axq-input__placeholder {
      font-size: 30px;
      color: #6f6d6d;
    }

    .icon-guanbi {
      padding-right: 20px;
      font-size: 50px;
      color: #d53383;

      &:active {
        filter: brightness(0.8);
      }
    }
  }
</style>