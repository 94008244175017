/*
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 14:19:42
 * @LastEditTime: 2022-02-15 10:41:56
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /m/src/config/app.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
export const appConfig = {
  tokenKey: 'user-access-token',
  bearerTokenKey: 'Authorization',
  appKey: 'app-access-key',
  staticUrl: 'https://aoxqwl-static.oss-cn-shenzhen.aliyuncs.com/image/web-m/',
  ossUrl: {
    dev: '//aoxqwl-imgtest.oss-cn-shenzhen.aliyuncs.com/',
    prod: '//aoxqwl-img.oss-cn-shenzhen.aliyuncs.com/'
  }
};
