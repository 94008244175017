var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { disabled: _vm.disabled || _vm.loading },
      on: { click: _vm.handleClick }
    },
    [
      _vm.loading
        ? _c("van-loading", { attrs: { size: _vm.loadingSize } })
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }