<template>
  <van-picker title="选择团队领导人" class="group-leader" :default-index="defaultIndex" ref="picker" show-toolbar
    :columns="columns"
    @change="handleChange"
    @confirm="handleConfirm" @cancel="$emit('cancel')">
  </van-picker>
</template>

<script>
  import {
    model
  } from '@/model';

  const {
    Member
  } = model.collection
  export default {
    name: 'GroupLeader',
    data() {
      return {
        params: {
          pageIndex: 1,
          pageSize: 10
        },
        defaultIndex:0,
        total: 0,
        columns: []
      }
    },
    created() {
      this.getPage();
    },
    methods: {
      getPage() {
        Member.sendApi('page', {
          params: this.params,
          data: {
            query: {
              isTeamLeader: 1
            }
          }
        }).then((res) => {
          if (res.status) {
            const {
              data,
              page
            } = res
            this.total = page.total;
            this.defaultIndex = this.params.pageIndex > 1 ? this.columns.length-1 : 0;
            data.forEach(item => {
              this.columns.push({
                text: item.nickname,
                id: item.id
              })
            })
          }
        })
      },
      handleConfirm(e) {
        this.$emit('confirm', e)
      },
      handleChange(picker, value, index) {
        let len = this.columns.length
        if ((len-1 === index) && (len < this.total)) {
          this.params.pageIndex++;
          this.getPage()
        }
      }
    }
  }
</script>