
import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';

import { localStorage } from '../libs/js/session';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user
  },
  state: {},
  mutations: {},
  actions: {
    initApp ({ dispatch, commit }) {
      // #ifdef MP-WEIXIN
      commit('SET_APP_PLATFORM', 'MP-WEIXIN');
      // #endif
      sessionLib.get() && dispatch('user/getUserInfo');
    }
  }
});

export default store;