var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "address-form" }, [
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("申请人")]),
      _c("span", { staticClass: "i-text" }, [_vm._v(_vm._s(_vm.applierName))])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("联系电话")]),
      _c("span", { staticClass: "i-text" }, [_vm._v(_vm._s(_vm.applierMobile))])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("申请地区")]),
      _c("span", { staticClass: "i-text i-text_region" }, [
        _vm._v(_vm._s(_vm.regionAllocateName))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("上级推荐人")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.inviteMember.nickname))
      ])
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("上级代理")]),
      _c("span", { staticClass: "i-text" }, [
        _vm._v(_vm._s(_vm.superiorDistributor.nickname))
      ])
    ]),
    _c("div", { staticClass: "f-item required" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("店铺地址")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.addressFull,
            expression: "form.addressFull"
          }
        ],
        staticClass: "i-input",
        attrs: { placeholder: "请输入店铺地址", type: "textarea" },
        domProps: { value: _vm.form.addressFull },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "addressFull", $event.target.value)
          }
        }
      })
    ]),
    _c("div", { staticClass: "f-item" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("店铺面积")]),
      _c("div", { staticClass: "i-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.addressArea,
              expression: "form.addressArea"
            }
          ],
          attrs: { type: "number", placeholder: "0" },
          domProps: { value: _vm.form.addressArea },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "addressArea", $event.target.value)
            }
          }
        }),
        _c("span", { staticClass: "i-unit" }, [_vm._v("㎡")])
      ])
    ]),
    _c("div", { staticClass: "f-item required" }, [
      _c("span", { staticClass: "i-text" }, [_vm._v("街道电话")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.addressContact,
            expression: "form.addressContact"
          }
        ],
        staticClass: "i-input",
        attrs: { placeholder: "请输入店铺所属街道电话" },
        domProps: { value: _vm.form.addressContact },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "addressContact", $event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }