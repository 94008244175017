var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-shop-stock" }, [
    _c("div", { staticClass: "s-title" }, [
      _vm._v("新店主进货请联系信息部-玲玲")
    ]),
    _c("div", { staticClass: "s-card" }, [
      _c("div", { staticClass: "c-title" }, [_vm._v("联系方式")]),
      _c("div", { staticClass: "c-info" }, [
        _c(
          "div",
          {
            staticClass: "i-item",
            on: {
              click: function($event) {
                return _vm.copy(_vm.wxUsername)
              }
            }
          },
          [
            _c("span", { staticClass: "i-label" }, [_vm._v("微信：")]),
            _c("div", { staticClass: "i-value" }, [
              _vm._v(" " + _vm._s(_vm.wxUsername || "--") + " "),
              _c("span", { staticClass: "v-text" }, [_vm._v("复制")])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "i-item",
            on: {
              click: function($event) {
                return _vm.copy(_vm.mobile)
              }
            }
          },
          [
            _c("span", { staticClass: "i-label" }, [_vm._v("电话：")]),
            _c("span", { staticClass: "i-value" }, [
              _vm._v(" " + _vm._s(_vm.mobile || "--") + " "),
              _c("span", { staticClass: "v-text" }, [_vm._v("复制")])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }