var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "axq-steps" },
    _vm._l(_vm.steps, function(s, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "axq-steps-item",
          class: { active: _vm.active >= i }
        },
        [
          _c("span", { staticClass: "step-value" }, [_vm._v(_vm._s(i + 1))]),
          _c("span", { staticClass: "step-name" }, [_vm._v(_vm._s(s.name))]),
          _c("div", { staticClass: "step-line" })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }