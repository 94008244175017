/*
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 13:57:17
 * @LastEditTime: 2022-01-15 09:09:16
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/main.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import { Log4js } from '@/util/log4js';
import dayjs from 'dayjs';
import Vant, { Dialog, Swipe, SwipeItem, Toast } from 'vant';
import 'vant/lib/index.css';
import Vue from "vue";
import Meta from 'vue-meta';
import App from "./App.vue";
import './assets/fonts/iconfont.less';
import './mixins';
import { router } from "./router";
import store from "./store";
const _ = require('lodash');


Vue.use(Meta);
Vue.config.productionTip = false;
Vue.$_ = Vue.prototype.$_ = window.$_ = _;
Vue.$log4js = Vue.prototype.$log4js = window.$log4js = new Log4js();
Vue.$toast = Vue.prototype.$toast = window.$toast = Toast;
Vue.$dialog = Vue.prototype.$dialog = window.$dialog = Dialog;
Vue.$dayjs = Vue.prototype.$dayjs = window.$dayjs = dayjs;

Vue.use(Vant);
Vue.use(Swipe);
Vue.use(SwipeItem);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
