var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-popup",
    {
      staticClass: "axq-dialog",
      attrs: { "use-slot": "" },
      on: { close: _vm.handleClose },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-main" },
        [
          _vm.type == "success"
            ? [
                _c("img", {
                  staticClass: "m-icon success",
                  attrs: { src: _vm._f("prefixOssUrl")("success.png"), alt: "" }
                })
              ]
            : _vm._e(),
          _vm.type == "error"
            ? [
                _c("img", {
                  staticClass: "m-icon error",
                  attrs: { src: _vm._f("prefixOssUrl")("error.png"), alt: "" }
                })
              ]
            : _vm._e(),
          _vm.title
            ? _c("div", { staticClass: "m-title" }, [_vm._v(_vm._s(_vm.title))])
            : _vm._e(),
          _vm.subTitle
            ? _c("div", { staticClass: "m-sub-title" }, [
                _vm._v(_vm._s(_vm.subTitle))
              ])
            : _vm._e(),
          _c("div", { staticClass: "m-btns" }, [_vm._t("btns")], 2)
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }