var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "region-search" }, [
    _c(
      "div",
      { staticClass: "s-main" },
      [
        _c("region-card", {
          staticClass: "m-item",
          attrs: {
            title: "区域查询",
            "img-url": _vm._f("prefixOssUrl")("apply/index/region.png")
          },
          on: {
            click: function($event) {
              return _vm.go("/apply/region/search/index")
            }
          }
        }),
        _c("region-card", {
          staticClass: "m-item",
          attrs: {
            title: "店铺查询",
            "img-url": _vm._f("prefixOssUrl")("apply/index/shop.png")
          },
          on: { click: _vm.developing }
        }),
        _c("region-card", {
          staticClass: "m-item",
          attrs: {
            title: "我的申请",
            "img-url": _vm._f("prefixOssUrl")("apply/index/my.png")
          },
          on: {
            click: function($event) {
              return _vm.go("/apply/approval/list")
            }
          }
        }),
        _c("region-card", {
          staticClass: "m-item",
          attrs: {
            title: "店铺保证金",
            "img-url": _vm._f("prefixOssUrl")("apply/index/deposit.png")
          },
          on: { click: _vm.developing }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }