<template>
  <div class="region-apply">
    <div class="a-title">申请人信息({{ isApplyForMyself?'个人':'他人' }}申请)</div>
    <div class="a-form">
      <template v-if="isApplyForMyself">
        <div class="f-item">
          <span class="i-text">申请人</span>
          <span class="i-text">{{ $_.get(info,'nickname') }}</span>
        </div>
        <div class="f-item">
          <span class="i-text">联系电话</span>
          <span class="i-text">{{ $_.get(info,'mobile') }}</span>
        </div>
      </template>
      <template v-else>
        <div class="f-item">
          <span class="i-text">联系电话</span>
          <input class="i-input" v-model="form.mobile" placeholder="请输入申请人电话" @input="searchInput" />
        </div>
        <div class="f-item">
          <span class="i-text">申请人</span>
          <span class="i-text">{{ $_.get(memberInfo,'nickname') }}</span>
        </div>
      </template>
      <div class="f-item">
        <span class="i-text">申请地区</span>
        <span class="i-text i-text_region">{{ blockInfo.regionFullName }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">上级推荐人</span>
        <span
          class="i-text">{{ isApplyForMyself ? $_.get(info,'parent.nickname') : $_.get(memberInfo,'parent.nickname') }}</span>
      </div>
      <div class="f-item">
        <span class="i-text">上级代理</span>
        <span
          class="i-text">{{ isApplyForMyself ? $_.get(info,'distributor.nickname') : $_.get(memberInfo,'distributor.nickname') }}</span>
      </div>
      <div class="f-item" @click="popupShow=true">
        <span class="i-text">团队领导人</span>
        <div>
          <span class="i-text">{{ selectedLeader.name ? selectedLeader.name : '请选择' }}</span>
          <span v-if="!selectedLeader.name" class="iconfont icon-xiajiantou"></span>
        </div>
      </div>
      <div class="f-item" v-if="!isApplyForMyself">
        <span class="i-text">提交人</span>
        <span class="i-text">{{ $_.get(info,'nickname') }}</span>
      </div>
    </div>
    <div class="a-btn">
      <a-btn type="primary" size="large" :class="{btnDisabled:'active'} " @click="submitApply">提交申请</a-btn>
    </div>

    <van-popup v-model="popupShow" position="bottom" :style="{ height: '50%' }">
      <group-leader-picker @confirm="confirmLeader" @cancel="popupShow=false">
      </group-leader-picker>
    </van-popup>

    <a-dialog :show="dialog.applyRes" :title="applyRes.title" :sub-title="applyRes.subTitle" :type="applyRes.type"
      @close="handelAdialogClose">
      <template v-slot:btns>
        <template v-if="applyRes.type == 'error'">
          <a-btn type="primary" hollow @click="handelAdialogClose">返回</a-btn>
          <a-btn type="primary" @click="back()">重新选择</a-btn>
        </template>
        <template v-else>
          <a-btn type="primary" @click="handelAdialogClose">返回</a-btn>
        </template>
      </template>
    </a-dialog>

  </div>
</template>

<script>
  import {
    mapState,
    mapActions
  } from 'vuex'

  import aBtn from '@/components/btn';
  import ADialog from '@/components/dialog';
  import GroupLeaderPicker from '@/components/picker/group-leader';

  import eventBus from '@/event'
  import {
    eventBlock
  } from '@/event/approval'

  import {
    model
  } from '@/model';
  const {
    Approval,
    Member
  } = model.collection;

  const ApprovalType = {
    REGION: 1,
    SHOP: 2
  }
  const ApprovalWay = {
    MYSELF: 1,
    OTHER: 2
  }

  export default {
    components: {
      aBtn,
      ADialog,
      GroupLeaderPicker
    },
    metaInfo() {
      return {
        title: '区域申请'
      }
    },
    data() {
      return {
        memberInfo: {},
        blockInfo: {},
        form: {
          nickname: '',
          mobile: ''
        },
        popupShow: false,
        selectedLeader: {},
        dialogShow: false,
        dialog: {
          applyRes: false
        },
        applyRes: {
          type: 'success',
          title: '抱歉！提交失败',
          subTitle: '该区域已被申请，请重新选择。'
        }
      }
    },
    computed: {
      ...mapState('user', ['info']),
      isApplyForMyself() {
        return this.blockInfo.approvalWay == ApprovalWay.MYSELF
      },
      formInvalidArr() {
        let arr = []
        if (!this.isApplyForMyself) {
          if (!this.form.nickname) {
            arr.push({
              msg: '请填写申请人姓名'
            })
          }
          if (!this.form.mobile) {
            arr.push({
              msg: '请填写申请人电话'
            })
          }
        }
        if (!this.selectedLeader.id) {
          arr.push({
            msg: '请选择团队领导人'
          })
        }
        return arr
      },
      btnDisabled() {
        return this.formInvalidArr.length !== 0;
      }
    },
    created() {
      this.blockInfo = this.$route.query;
      this.getUserInfo();
    },
    methods: {
      ...mapActions('user', ['getUserInfo']),
      searchInput(e) {
        Member.sendApi('info', {
          params: {},
          data: {
            query: {
              mobile: e.target.value
            }
          }
        }).then((res) => {
          if (res.status) {
            if (!res.data) return this.$toast('暂无此会员信息，请重新输入手机号码')
            this.memberInfo = res.data
          }
        })
      },
      confirmLeader(e) {
        this.selectedLeader.id = e.id;
        this.selectedLeader.name = e.text;
        this.popupShow = false;
      },
      showRes(type = 'success', {
        title,
        subTitle
      } = {}) {
        if (type == 'success') {
          this.applyRes = {
            type: 'success',
            title: '恭喜您！提交成功。',
            subTitle: '可进入“我的申请”查看进度详情。'
          }
        } else if (type == 'error') {
          this.applyRes = {
            type: 'error',
            title: title || '抱歉！提交失败。',
            subTitle: subTitle || '该区域已被申请,请重新选择'
          }
        }
        this.dialog.applyRes = true
      },
      handelAdialogClose() {
        if (this.applyRes.type == 'success') {
          this.back()
        } else {
          this.dialog.applyRes = false
        }
      },
      submitApply() {
        if (!this.isApplyForMyself) {
          if (!this.form.mobile) return this.$toast('请填写申请人电话')
        }
        this.$dialog.confirm({
          title: '确认提交申请',
          asyncClose: true
        }).then(() => {
          let applier = {}
          if (this.isApplyForMyself) {
            applier = this.info
          } else {
            applier = this.memberInfo
          }
          let form = {
            typeId: ApprovalType.REGION,
            name: this.blockInfo.regionFullName,
            approvalSubRegionAllocateAgent: {
              type: this.blockInfo.approvalWay,
              applierId: applier.id,
              creatorId: this.info.id,
              regionAllocateId: this.blockInfo.allocateId,
              inviteMemberId: this.info.parent.id,
              superiorDistributorId: this.info.distributor.id,
              topDistributorId: this.selectedLeader.id
            }
          }
          Approval.sendApi('add', {
            params: {},
            data: form
          }).then(res => {
            if (res.status) {
              this.showRes()
            } else {
              this.showRes('error', {
                subTitle: res.message
              })
            }
          }).finally(() => {
            eventBus.$emit(eventBlock.FRESH_RESULT)
          })
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="less" scoped>
  .region-apply {
    padding: 0 30px;
    background: #fff;

    .a-title {
      padding: 29px 0;
      font-size: 36px;
      font-weight: bold;
      color: #333;
    }

    .a-form {
      .f-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        margin-top: 20px;
        border-bottom: 1px solid #ccc;

        .i-text {
          font-size: 32px;
          font-weight: 500;
          color: #666666;

          &_region {
            width: 500px;
            text-align: right;
          }
        }

        .i-input {
          border: none;
          text-align: right;
          font-size: 32px;
        }
      }
    }

    /deep/.a-btn {
      position: fixed;
      bottom: 50px;

      .axq-btn {
        width: 690px;
      }
    }

    /deep/ a-dialog {
      a-btn:not(:first-of-type) {
        margin-left: 20px;
      }

      .axq-btn {
        width: 200px;
        height: 60px;
        line-height: 60px;
        border: 2px solid #dc117d;
        border-radius: 30px;
      }
    }
  }
</style>