<template>
  <div class="region-search-result">
    <van-sticky>
      <div class="status-filter">
        <div type="text" class="status-item" :class="{active:form.status == 1}" @click="setStatus(1)">
          <img class="status-item-icon" :src="'apply/search/result/can-apply.png'|prefixOssUrl" />
          <p class="status-item-text">可申请</p>
        </div>
        <div type="text" class="status-item" :class="{active:form.status == 2}" @click="setStatus(2)">
          <img class="status-item-icon" :src="'apply/search/result/searching.png'|prefixOssUrl" />
          <p class="status-item-text">寻找中</p>
        </div>
        <div type="text" class="status-item" :class="{active:form.status == 3}" @click="setStatus(3)">
          <img class="status-item-icon" :src="'apply/search/result/decorating.png'|prefixOssUrl" />
          <p class="status-item-text">装修中</p>
        </div>
        <div type="text" class="status-item" :class="{active:form.status == 4}" @click="setStatus(4)">
          <img class="status-item-icon" :src="'apply/search/result/opening.png'|prefixOssUrl" />
          <p class="status-item-text">已开业</p>
        </div>
      </div>
      <div class="table-header">
        <div class="table-column"  style="width: 100px">省</div>
        <div class="table-column"  style="width: 100px">市</div>
        <div class="table-column" >区</div>
        <div class="table-column column-btn">区域范围</div>
        <div class="table-column column-btn" style="text-align: right">操作</div>
      </div>
    </van-sticky>
    <div class="table-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div v-for="(d,i) in regionList" :key="i" class="table-row" :class="{
              'apply': d.status==1,
              'searching': d.status==2,
              'decorating': d.status==3,
              'opening': d.status==4,
          }">
            <div class="table-column column-p" style="width: 100px">{{ d.provinceName || '--' }}</div>
            <div class="table-column column-c" style="width: 100px">{{ d.cityName|| '--' }}</div>
            <div class="table-column column-d">{{ d.districtName||'--' }}</div>
            <!-- <div class="table-column column-d">{{ filterSysRegionList(d.sysRegionList)  ||'--' }}</div> -->
            <div class="table-column column-n">{{ d.name||'--' }}</div>
            <div class="table-column column-btn">
              <div class="btn" @click="openDetail(d)">详情</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <van-popup v-model="popupShow" close-on-click-overlay closeable @close="popupShow=false">
      <div class="area-popup">
        <div class="p-title">街道</div>
        <div class="p-area">
          <div class="area-item" v-for="(a,i) in remarkList" :key="i">{{ a }}</div>
        </div>
        <div v-show="false" class="p-btn" v-if="form.status === 1">
          <div class="b-item" @click="apply(2)">他人区域申请</div>
          <div class="b-item" @click="apply(1)">个人区域申请</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
  import {
    model
  } from '@/model';

  const {
    Region
  } = model.collection;
  export default {
    metaInfo() {
      return {
        title: '查询结果'
      }
    },
    data() {
      return {
        loading: false,
        finished: false,
        refreshing: false,
        total: 0,
        params: {
          pageIndex: 1,
          pageSize: 20
        },
        form: {},
        popupShow: false,
        regionList: [],
        remarkList: [],
        selectedRegion: {}
      }
    },
    computed: {
      statusClass() {
        return () => {
          return 'table-row tr'
        }
      },
      blockFullName() {
        let provinceName = this.selectedRegion.provinceName,
          cityName = this.selectedRegion.cityName,
          districtName = this.selectedRegion.districtName,
          name = this.selectedRegion.name
        let arr = [provinceName, cityName, districtName, name]
        arr = arr.filter(a => a)
        return arr.join('-')
      }
    },
    created() {
      this.form = this.$route.query;
      this.form.status = 1;
      this.form.isEnableRegionApply = 1;
    },
    methods: {
      filterSysRegionList(arr) {
        return arr && arr.length ?  arr.map(item => item.name).join(',') : '--'
      },
      init() {
        this.regionList = [];
        this.params.pageIndex = 1;
        // this.getRegionList();
      },
      onLoad() {
        if (!this.finished) {
          this.getRegionList();
        }
      },
      onRefresh() {
        this.refreshing = false;
        this.init();
      },
      getRegionList() {
        Region.sendApi('allocatePage', {
          params: this.params,
          data: {
            query: this.form
          }
        }).then(res => {
          const {
            status,
            data,
            page
          } = res;
          if (status) {
            this.loading = false;
            this.total = page.total;
            this.regionList = this.regionList.concat(data);
            if(this.regionList.length >= this.total) {
              this.finished = true
            }
            this.params.pageIndex++;
          }
        })
      },
      setStatus(status) {
        this.form.status = status
        this.finished = false
        this.init()
        // this.getRegionList()
      },
      openDetail(r) {
        this.selectedRegion = r
        if (r.remark) {
          this.remarkList = r.remark.split(',')
        }
        if (r.sysRegionList) {
          this.remarkList = r.sysRegionList.map(item=>item.name)
        }
        this.popupShow = true
      },
      apply(type) {
        let blockFullName = this.blockFullName
        let param = `regionFullName=${blockFullName}&allocateId=${this.selectedRegion.id}&approvalWay=${type}`
        this.go(`/apply/region/add?${param}`)
      }
    }
  }
</script>

<style lang="less" scoped>
  .region-search-result {
    height: 100vh;
    background: #f7f7f7;

    .status-filter {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 15px 0;
      background: #fff;

      .status-item {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:not(:first-of-type) {
          margin-left: 22px;
        }

        &-icon {
          width: 56px;
          height: 70px;
        }

        &-text {
          font-size: 30px;
          font-weight: 500;
          color: #666;
        }

        &:nth-of-type(1) {
          &.active {
            border: 2px solid #22ddff;
          }
        }

        &:nth-of-type(2) {
          &.active {
            border: 2px solid #ffbe22;
          }
        }

        &:nth-of-type(3) {
          &.active {
            border: 2px solid #c822ff;
          }
        }

        &:nth-of-type(4) {
          &.active {
            border: 2px solid #ff2266;
          }
        }
      }

    }

    .table-header {
      display: flex;
      background: #fff;
      border-top: 2px solid #e6e6e6;
      padding: 27px 20px 30px;

      .table-column {
        padding: 0 10px;
        width: 110px;
        font-size: 34px;

        &:nth-last-child(1) {
          width: 139px;
        }
      }
    }

    .table-body {
      background: #f7f7f7;

      .table-row {
        display: flex;
        padding: 0 20px;
        border-bottom: 2px solid #e6e6e6;

        .table-column {
          display: flex;
          align-items: center;
          padding: 31px 10px;
          font-size: 30px;
          color: #22ddff;
        }

        &.searching .table-column {
          color: #ffbe22;
        }

        &.decorating .table-column {
          color: #c822ff;
        }

        &.opening .table-column {
          color: #ff2266;
        }
      }

      .column-p {
        width: 110px;
        word-break: break-all;
        box-sizing: border-box;
      }

      .column-c {
        width: 130px;
        word-break: break-all;
        box-sizing: border-box;
      }

      .column-d {
        width: 130px;
        box-sizing: border-box;
      }

      .column-n {
        flex: 1;
      }

      .column-btn {
        flex: unset !important;
        width: 110px;

        .btn {
          width: 100px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #DB187C;
          color: #fff;
          font-size: 26px;
          border-radius: 22px;
        }
      }
    }

    /deep/ .van-popup {
      width: 690px;
      border-radius: 20px;
    }

    .area-popup {
      width: 690px;

      .p-title {
        padding: 38px 0;
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        color: #060505;
        border-bottom: 2px solid #ccc;
      }

      .p-area {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 17px 20px;
        max-height: 473px;
        overflow-y: auto;

        .area-item {
          height: 60px;
          padding: 0 10px;
          margin-right: 15px;
          margin-bottom: 30px;
          background: #e6e6e6;
          border-radius: 4px;
          text-align: center;
          line-height: 60px;
          font-size: 30px;
        }
      }

      .p-btn {
        display: flex;
        width: 690px;
        height: 100px;

        border-top: 1px solid #ccc;
        border-radius: 0px 0px 20px 20px;

        .b-item {
          flex: 1;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 34px;
          color: #DB187C;

          &:first-of-type {
            color: #000;
            border-right: 2px solid #ccc;
          }
        }
      }
    }
  }
</style>