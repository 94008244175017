var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-picker", {
    ref: "picker",
    staticClass: "region-picker",
    attrs: {
      "show-toolbar": "",
      "cancel-button-text": _vm.cancelButtonText,
      columns: _vm.pManager.pickerColumns
    },
    on: {
      change: _vm.handleChange,
      confirm: _vm.handleConfirm,
      cancel: _vm.handleCancel
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }