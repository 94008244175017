<template>
  <van-popup class="axq-dialog" use-slot v-model="show" @close="handleClose">
    <div class="d-main">
      <template v-if="type=='success'">
        <img class="m-icon success" :src="'success.png'|prefixOssUrl" alt="" />
      </template>
      <template v-if="type=='error'">
        <img class="m-icon error" :src="'error.png'|prefixOssUrl" alt="" />
      </template>
      <div v-if="title" class="m-title">{{ title }}</div>
      <div v-if="subTitle" class="m-sub-title">{{ subTitle }}</div>
      <div class="m-btns">
        <slot name="btns"></slot>
      </div>
    </div>
  </van-popup>
</template>

<script>
  export default {
    name: 'AxqDialog',
    props: {
      show: Boolean,
      title: String,
      subTitle: String,
      type: String
    },
    methods: {
      handleClose(e) {
        this.$emit('close', e)
      }
    }
  }
</script>

<style lang="less">
  .axq-dialog {
    border-radius: 10px;

    .d-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 500px;
      padding: 68px 30px 46px;

      .m-icon {
        width: 100px;
        height: 100px;
      }

      .m-title {
        margin-top: 48px;
        font-size: 36px;
        font-weight: bold;
        color: #333;
        line-height: 36px;
        text-align: center;
      }

      .m-sub-title {
        margin-top: 26px;
        font-size: 24px;
        color: #999;
        line-height: 36px;
        text-align: center;
      }

      .m-btns {
        display: flex;
        justify-content: space-evenly;
        margin-top: 110px;
        width: 100%;

        .axq-btn {
          width: 200px !important;
          height: 60px;
          line-height: 60px;
          border-radius: 30px;
          border: 1px solid #db187c;
        }
      }
    }
  }
</style>