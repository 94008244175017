var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "region-card",
      on: {
        click: function($event) {
          return _vm.handleClick()
        }
      }
    },
    [
      _c("span", { staticClass: "region-card-title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("img", { staticClass: "region-card-bg", attrs: { src: _vm.imgUrl } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }