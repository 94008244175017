/*
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 13:57:17
 * @LastEditTime: 2021-06-07 14:07:57
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /m/src/router/index.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes.js';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes
});
