var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feedback-select" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "tip" }, [
        _vm._v(
          " 为了给您提供更加优质的服务，傲雪棋特邀您参与“意见反馈”调查活动，让我们了解您的想法，倾听您的心声。敬请大家本着实事求是的原则，对傲雪棋公司或店铺进行服务反馈，对于您们提出的宝贵意见和建议，我们将虚心采纳并及时处理，感谢您提供的宝贵意见！ "
        )
      ]),
      _c("div", { staticClass: "title" }, [_vm._v("请选择您的身份")]),
      _c("div", { staticClass: "select" }, [
        _c(
          "div",
          {
            staticClass: "s-item",
            class: { active: _vm.selectType === 2 },
            on: {
              click: function($event) {
                return _vm.choose(2)
              }
            }
          },
          [
            _vm._v(" 店主 "),
            _vm.selectType === 2
              ? _c("img", {
                  attrs: { src: require("assets/selected.png"), alt: "" }
                })
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "s-item",
            class: { active: _vm.selectType === 1 },
            on: {
              click: function($event) {
                return _vm.choose(1)
              }
            }
          },
          [
            _vm._v(" 消费者 "),
            _vm.selectType === 1
              ? _c("img", {
                  attrs: { src: require("assets/selected.png"), alt: "" }
                })
              : _vm._e()
          ]
        )
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "btn",
        class: { active: _vm.selectType === 1 || _vm.selectType === 2 },
        on: {
          click: function($event) {
            return _vm.goTo(1)
          }
        }
      },
      [_vm._v("下一步")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("assets/axq-logo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }