/*
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 11:02:11
 * @LastEditTime: 2022-01-15 11:23:13
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/approval/@js/apply-mixin.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import ProgressManager from './ProgressManager'

export default {
  props: {
    pManager: Object
  },
  data () {
    return {
      manager: new ProgressManager()
    }
  },
  computed: {
    typeKey () {
      return this.$_.get(this.manager, 'typeKey')
    },
    applierName () {
      return this.manager.applierName
    },
    applierMobile () {
      return this.manager.applierMobile
    },
    regionAllocateName () {
      return this.manager.regionAllocateName
    },
    addressFull () {
      return this.manager.addressFull
    },
    addressArea () {
      return this.manager.addressArea
    },
    addressContact () {
      return this.manager.addressContact
    },
    tenancyImgList () {
      return this.manager.tenancyImgList
    },
    inviteMember () {
      return this.$_.get(this.manager.info, `${this.typeKey}.inviteMember`) || {}
    },
    superiorDistributor () {
      return this.$_.get(this.manager.info, `${this.typeKey}.superiorDistributor`) || {}
    },
    topDistributor () {
      return this.$_.get(this.manager.info, `${this.typeKey}.topDistributor`) || {}
    }
  },
  created () {
    this.manager.info = this.pManager.info
  }
}