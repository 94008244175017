/*
 * @Author: 码上talk|RC
 * @Date: 2021-08-26 09:08:25
 * @LastEditTime: 2022-05-05 09:36:27
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/model/entity/axq/axqFeedback.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-15 10:31:52
 * @LastEditTime: 2021-06-07 14:16:05
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /m/src/model/entity/_test/test.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import Entity from '../../entity.class.js';

const _tableField = {
  type: {
    type: 'int',
    default: 0
  },
  content: {
    type: 'string',
    default: ''
  }
};

class AxqFeedback extends Entity {
  static _requestConfig = {
    app: 'm',
    domain: 'axq'
  }

  static _form = {}

  static _options = {}

  constructor (axqFeedback) {
    super(axqFeedback, { _tableField });
  }
}

export default AxqFeedback;
