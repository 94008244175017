<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-26 11:01:09
 * @LastEditTime: 2022-01-10 09:43:01
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/axq/feedback/add.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="feedback-add">
    <textarea name cols="40" rows="22" placeholder="请在此输入您对我们的工作给予评价或提出宝贵的建议！" v-model="value"
      class="a-textarea"></textarea>
    <div class="btn" :class="{ active:isValue }" @click="isValue ? submit() : ''">提交反馈</div>
    <div class="mask" v-if="isShow" @click.stop="goBack"></div>
    <div class="popup" v-if="isShow">
      <img src="~assets/success.png" alt />
      <div class="title">提交成功</div>
      <div class="tip">您的建议已收悉，感谢您对傲雪棋的支持与监督！</div>
      <div class="btn" @click.stop="goBack">返回</div>
    </div>
  </div>
</template>

<script>
  import {
    model
  } from '@/model';

  const {
    AxqFeedback
  } = model.collection;

  export default {
    metaInfo() {
      return {
        title: '填写意见'
      }
    },
    data() {
      return {
        value: '',
        id: '',
        isShow: false
      }
    },
    created() {
      this.id = this.$route.query.type
    },
    mounted() {},
    computed: {
      isValue() {
        if (this.value !== '') return true
        return false
      }
    },
    methods: {
      submit() {
        AxqFeedback.sendApi('feedbackAdd', {
          params: {},
          data: {
            type: this.id,
            content: this.value
          }
        }).then(res => {
          const {
            status
          } = res
          if (status) {
            this.isShow = true
            this.value = ''
          }
        })
      },
      goBack() {
        this.$router.go(-1)
      }
    }
  }
</script>
<style lang="less">
  .feedback-add {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .a-textarea {
      width: 660px;
      height: 930px;
      background: #f7f7f7;
      border: 0;
      padding: 20px;
      resize: none;
      font-size: 30px;
    }

    textarea::-webkit-input-placeholder {
      color: #9c9c9c;
    }

    textarea::-moz-placeholder {
      color: #9c9c9c;
    }

    textarea:-moz-placeholder {
      color: #9c9c9c;
    }

    textarea:-ms-input-placeholder {
      color: #9c9c9c;
    }

    .btn {
      position: fixed;
      bottom: 50px;
      width: 690px;
      height: 90px;
      background: #ea6fb6;
      border-radius: 10px;
      text-align: center;
      line-height: 90px;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }

    .active {
      background: #db187c;
    }

    .mask {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .popup {
      width: 500px;
      height: 520px;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      left: 17%;
      top: 28%;
      background: #ffffff;
      border-radius: 10px;

      img {
        width: 100px;
        height: 100px;
        margin-top: 90px;
        margin-bottom: 30px;
      }

      .title {
        height: 35px;
        margin-bottom: 30px;
        font-size: 36px;
        text-align: center;
        line-height: 35px;
        font-weight: bold;
        color: #333333;
      }

      .tip {
        width: 400px;
        height: 23px;
        font-size: 24px;
        text-align: center;
        line-height: 38px;
        font-weight: 500;
        color: #999999;
      }

      .btn {
        position: absolute;
        bottom: 8%;
        width: 200px;
        height: 60px;
        background: #db187c;
        border-radius: 30px;
        text-align: center;
        line-height: 60px;
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
</style>