<!--
 * @Author: 码上talk|RC
 * @Date: 2021-07-03 15:34:12
 * @LastEditTime: 2022-03-07 14:15:31
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/components/picker/block.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <van-picker class="region-picker" ref="picker" show-toolbar :cancel-button-text="cancelButtonText"
    :columns="pManager.pickerColumns"  @change="handleChange"
    @confirm="handleConfirm" @cancel="handleCancel"></van-picker>
</template>

<script>
  import PickerManager from './PickerManager.class'
  import {
    model
  } from '@/model';

  const {
    Region
  } = model.collection;
  export default {
    props: {
      cancelButtonText: {
        type: String,
        default: '取消'
      }
    },
    data() {
      return {
        params:{
          pageIndex:1,
          pageSize: 100
        },
        pManager: new PickerManager(),
      }
    },
    computed: {
      picker() {
        return this.$refs.picker
      }
    },
    created() {
      this.pManager.addColumns({
        values: [],
        defaultIndex: 0
      })
    },
    methods: {
      getPage(e) {
        Region.sendApi('allocatePage', {
          params: this.params,
          data: {
            query: e
          }
        }).then(res => {
          if (res.status) {
            const {
              data
            } = res
            if (data.length === 0) {
              this.$emit('no-data')
            }
            let list = []
            data.map(v => {
              if(v.visible === 1) {
                list.push(v)
              }
            })
            this.pManager.pushColumnValues(list, 0)
          }
          return res
        })
      },
      handleConfirm(e) {
        let values = this.pManager.getValues(e)
        this.$emit('confirm', values)
      },
      handleCancel() {
        this.$emit('cancel')
      },
      handleChange(e) {
        let {
          picker
        } = e.detail
        let index = picker.getColumnIndex(0)
        let values = this.pManager.columns[0].values
      },
      setPageData(form) {
        this.getPage(form)
      }
    }
  }
</script>