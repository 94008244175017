var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply-card", on: { click: _vm.toApplyProgress } },
    [
      _c("div", { staticClass: "apply-card-title" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: _vm._f("prefixOssUrl")(_vm.applyIcon) }
        }),
        _c("span", { staticClass: "apply-type" }, [
          _vm._v(_vm._s(_vm.applyType))
        ]),
        !_vm.isCancel
          ? _c("div", { staticClass: "detail" }, [
              _c("span", { staticClass: "detail-text" }, [_vm._v("详情")]),
              _c("span", { staticClass: "detail-text" }, [_vm._v(">")])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "apply-card-info" }, [
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "info-item-label" }, [_vm._v("申请地区")]),
          _c("div", { staticClass: "info-item-value region" }, [
            _vm._v(
              _vm._s(_vm.$_.get(_vm.data, "provinceName", "")) +
                "-" +
                _vm._s(_vm.$_.get(_vm.data, "cityName", "")) +
                "-" +
                _vm._s(_vm.$_.get(_vm.data, "districtName", "")) +
                "-" +
                _vm._s(_vm.$_.get(_vm.data, "regionAllocateName", "")) +
                " "
            )
          ])
        ]),
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "info-item-label" }, [_vm._v("申请时间")]),
          _c("div", { staticClass: "info-item-value" }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.data, "createTime")))
          ])
        ]),
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "info-item-label" }, [_vm._v("申请人")]),
          _c("div", { staticClass: "info-item-value" }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.data, "applierName")))
          ])
        ]),
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "info-item-label" }, [_vm._v("联系电话")]),
          _c("div", { staticClass: "info-item-value" }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.data, "applierMobile")))
          ])
        ]),
        _vm.data.typeId === 1 && _vm.data.type === 2
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-item-label" }, [_vm._v("提交人")]),
              _c("div", { staticClass: "info-item-value" }, [
                _vm._v(_vm._s(_vm.$_.get(_vm.data, "creatorName")))
              ])
            ])
          : _vm._e(),
        _vm.data.typeId === 2
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-item-label" }, [
                _vm._v("区域拥有人")
              ]),
              _c("div", { staticClass: "info-item-value" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.get(_vm.data, "regionAllocateAgentName") || "--"
                  )
                )
              ])
            ])
          : _vm._e(),
        _vm.data.typeId === 2
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "info-item-label" }, [
                _vm._v("联系电话")
              ]),
              _c("div", { staticClass: "info-item-value" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.get(_vm.data, "regionAllocateAgentMobile") || "--"
                  )
                )
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "info-item progress",
            class: {
              cancel: _vm.data.status == 4,
              reject: _vm.data.status == 3,
              success: _vm.data.status == 2
            }
          },
          [
            _c("div", { staticClass: "info-item-label" }, [_vm._v("进度")]),
            _c("div", { staticClass: "info-item-value" }, [
              _vm._v(_vm._s(_vm.curWorkFlow || "--"))
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }