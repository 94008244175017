<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 10:54:52
 * @LastEditTime: 2022-03-14 17:31:25
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/region/search/index.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="region-search">
    <img class="s-header" :src="'apply/search/header-region.png'|prefixOssUrl" />
    <div class="s-form">
      <div class="f-item">
        <p class="i-label">搜索区域</p>
        <div class="i-input">
          <a-input placeholder="请输入区域名称，如“北京市”" placeholder-class="placeholder" clearable v-model="keyword"></a-input>
        </div>
      </div>
      <div class="f-item">
        <p class="i-label">选择区域</p>
        <div class="i-select" @click="popupShow = true">
          <div class="s-value" :class="{ 's-active' : regionName }">{{ regionName ? regionName  : ' 请选择区域，如“北京市” ' }}
          </div>
          <i v-if="regionName" class="iconfont icon icon-guanbi" @click.stop="regionName = '' "></i>
          <i v-else class="iconfont icon icon-xiajiantou"></i>
        </div>
      </div>
    </div>
    <p class="s-tips">注：可以任意选择一种方式进行查询</p>
    <div class="s-btn" :class="{'active': regionName || keyword }" @click="search">搜索</div>
    <van-popup v-model="popupShow" position="bottom" :style="{height: '60%'}">
      <region-picker custom-height="300" @confirm="confirmRegion" @cancel="popupShow = false"></region-picker>
    </van-popup>
  </div>
</template>

<script>
  import aInput from '@/components/input';
  import RegionPicker from '@/components/picker/region';

  export default {
    components: {
      aInput,
      RegionPicker
    },
    metaInfo() {
      return {
        title: '区域查询'
      }
    },
    data() {
      return {
        keyword: '',
        regionName: '',
        popupShow: false,
        regionPickerValue: {}
      }
    },
    methods: {
      confirmRegion(e) {
        this.popupShow = false
        this.regionPickerValue = e
        if (e.values) {
          let arr = []
          e.values.forEach((v) => {
            if (v.name) {
              arr.push(v.name)
            }
          })
          this.regionName = arr.join('-')
        }
      },
      search() {
        if (this.regionName || this.keyword) {
          let provinceId = this.$_.get(this.regionPickerValue, 'pcds.provinceId', ''),
            cityId = this.$_.get(this.regionPickerValue, 'pcds.cityId', ''),
            districtId = this.$_.get(this.regionPickerValue, 'pcds.districtId', '')
          let param = `keyword=${this.keyword}&provinceId=${provinceId}&cityId=${cityId}&districtId=${districtId}`
          this.go(`/apply/region/search/result?${param}`)
        }else {
          this.$toast('请选择区域');
        }
      }
    },
    created() {
      window.onload = function () {
        // 阻止双击放大
        var lastTouchEnd = 0;
        document.addEventListener('touchstart', function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        });
        document.addEventListener('touchend', function (event) {
          var now = (new Date()).getTime();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        }, false); // 阻止双指放大
        document.addEventListener('gesturestart', function (event) {
          event.preventDefault();
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .region-search {
    padding: 0 30px;

    .s-header {
      margin: 30px 0;
      width: 690px;
      height: 240px;
    }

    .s-form {
      .f-item {
        margin-bottom: 50px;

        .i-label {
          margin-bottom: 20px;
          font-size: 34px;
          font-weight: bold;
          color: #333333;
        }

        .i-input {
          width: 690px;
          height: 90px;
          border: 1px solid #333333;
        }

        .i-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 690px;
          height: 90px;
          border: 1px solid #333333;

          .s-value {
            flex: 1;
            height: 90px;
            padding-left: 20px;
            font-size: 30px;
            font-weight: 500;
            color: #999999;
            line-height: 90px;
          }

          .s-active {
            color: #333;
          }

          .icon {
            width: 90px;
            height: 90px;
            font-size: 60px;
            text-align: center;
            line-height: 90px;

            &-guanbi {
              color: #db197f;
            }

            &-xiajiantou {
              color: #999;
            }
          }
        }
      }
    }

    .s-tips {
      margin-top: 29px;
      font-size: 26px;
      font-weight: 500;
      color: #f00;
    }

    .s-btn {
      position: fixed;
      bottom: 40px;
      width: 690px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      font-size: 30px;
      color: #fff;
      background: #e771b6;
      border-radius: 10px;
    }

    .active {
      background: #db197f;
    }

  }
</style>