<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-26 08:12:36
 * @LastEditTime: 2022-01-17 08:28:33
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/axq/feedback/index.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="feedback-select">
    <div class="logo">
      <img src="~assets/axq-logo.png" />
    </div>
    <div class="content">
      <div class="tip">
        为了给您提供更加优质的服务，傲雪棋特邀您参与“意见反馈”调查活动，让我们了解您的想法，倾听您的心声。敬请大家本着实事求是的原则，对傲雪棋公司或店铺进行服务反馈，对于您们提出的宝贵意见和建议，我们将虚心采纳并及时处理，感谢您提供的宝贵意见！
      </div>
      <div class="title">请选择您的身份</div>
      <div class="select">
        <div class="s-item" :class="{active:selectType === 2 }" @click="choose(2)">
          店主
          <img v-if="selectType === 2" src="~assets/selected.png" alt />
        </div>
        <div class="s-item" :class="{active:selectType === 1 }" @click="choose(1)">
          消费者
          <img v-if="selectType === 1" src="~assets/selected.png" alt />
        </div>
      </div>
    </div>
    <div class="btn" :class="{active: selectType === 1 || selectType === 2 }" @click="goTo(1)">下一步</div>
  </div>
</template>

<script>
  export default {
    metaInfo() {
      return {
        title: '我们正在聆听您的意见 —— 傲雪棋'
      }
    },
    data() {
      return {
        selectType: '',
      }
    },
    methods: {
      choose(e) {
        this.selectType = e
      },
      goTo() {
        this.go(`/axq/feedback/add?type=${this.selectType}`)
      }
    }
  }
</script>
<style lang="less">
  .feedback-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 650px;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 267px;
        height: 142px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .tip {
        width: 650px;
        line-height: 60px;
        padding: 20px;
        background: #f7f7f7;
        font-size: 32px;
        font-weight: 500;
      }

      .title {
        margin-top: 60px;
        height: 31px;
        font-size: 32px;
        font-weight: 500;
        color: #000000;
      }

      .select {
        margin-top: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .s-item {
          position: relative;
          width: 200px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          margin-right: 30px;
          font-size: 30px;
          background: #ffffff;
          border: 1px solid #999999;
          border-radius: 10px;

          img {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
          }
        }

        .active {
          border: 1px solid #db187c;
          color: #db187c;
        }
      }
    }

    .btn {
      position: fixed;
      bottom: 50px;
      width: 690px;
      height: 90px;
      background: #ea6fb6;
      border-radius: 10px;
      text-align: center;
      line-height: 90px;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }

    .active {
      background: #db187c;
    }
  }
</style>