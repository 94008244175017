var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "piker-region" }, [
    _c("div", { staticClass: "r-btns" }, [
      _c(
        "div",
        { staticClass: "btn cancel", on: { click: _vm.handleCancel } },
        [_vm._v("取消")]
      ),
      _c(
        "div",
        { staticClass: "btn confirm", on: { click: _vm.handleConfirm } },
        [_vm._v("确认")]
      )
    ]),
    _c("div", { staticClass: "r-tabs" }, [
      _vm.labels.length !== 0
        ? _c(
            "div",
            { staticClass: "t-list" },
            _vm._l(_vm.labels, function(l, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "t-item",
                  class:
                    _vm.active && l.id === _vm.activeIndex
                      ? "t-item--active"
                      : "",
                  on: {
                    click: function($event) {
                      return _vm.handleTabChange(l)
                    }
                  }
                },
                [_vm._v(_vm._s(l.name))]
              )
            }),
            0
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "r-main", style: { height: _vm.customHeight + "px" } },
      [
        _c(
          "div",
          { staticClass: "m-list" },
          _vm._l(_vm.regionList, function(r, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "l-item",
                class: _vm.activeIndex === r.id ? "-item--active" : "",
                on: {
                  click: function($event) {
                    return _vm.handleRegionSelect(r)
                  }
                }
              },
              [_vm._v(_vm._s(r.name))]
            )
          }),
          0
        ),
        _vm.regionList.length === 0
          ? _c("div", { staticClass: "m-empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }