<!--
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 14:30:16
 * @LastEditTime: 2021-06-07 14:30:17
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /m/src/views/index/index.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="index"></div>
</template>
<script>
location.href='/axq/feedback/index'
</script>