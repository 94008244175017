var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feedback-add" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value"
        }
      ],
      staticClass: "a-textarea",
      attrs: {
        name: "",
        cols: "40",
        rows: "22",
        placeholder: "请在此输入您对我们的工作给予评价或提出宝贵的建议！"
      },
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.value = $event.target.value
        }
      }
    }),
    _c(
      "div",
      {
        staticClass: "btn",
        class: { active: _vm.isValue },
        on: {
          click: function($event) {
            _vm.isValue ? _vm.submit() : ""
          }
        }
      },
      [_vm._v("提交反馈")]
    ),
    _vm.isShow
      ? _c("div", {
          staticClass: "mask",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.goBack.apply(null, arguments)
            }
          }
        })
      : _vm._e(),
    _vm.isShow
      ? _c("div", { staticClass: "popup" }, [
          _c("img", { attrs: { src: require("assets/success.png"), alt: "" } }),
          _c("div", { staticClass: "title" }, [_vm._v("提交成功")]),
          _c("div", { staticClass: "tip" }, [
            _vm._v("您的建议已收悉，感谢您对傲雪棋的支持与监督！")
          ]),
          _c(
            "div",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            },
            [_vm._v("返回")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }