var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-apply" },
    [
      _c("van-sticky", { attrs: { "offset-top": 0 } }, [
        _c("div", { staticClass: "a-tabs" }, [
          _c(
            "div",
            {
              staticClass: "t-tab",
              class: { "t-tab_active": _vm.type == 1 },
              on: {
                click: function($event) {
                  return _vm.changeType(1)
                }
              }
            },
            [_vm._v("区域")]
          ),
          _c(
            "div",
            {
              staticClass: "t-tab",
              class: { "t-tab_active": _vm.type == 2 },
              on: {
                click: function($event) {
                  return _vm.changeType(2)
                }
              }
            },
            [_vm._v("店铺")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "apply-list" },
        [
          _c(
            "van-pull-refresh",
            {
              attrs: { "success-text": "刷新成功" },
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.listData, function(a, i) {
                  return _c("apply-card", { key: i, attrs: { data: a } })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }