/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-15 10:31:52
 * @LastEditTime: 2021-08-28 21:39:51
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /m/src/model/entity/_test/index.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import Entity from '../../entity.class.js';

const _tableField = {
  id: {
    type: 'int',
    default: 0
  },
  code: {
    type: 'string',
    default: ''
  },
  createTime: {
    type: 'string',
    default: ''
  }
};

class Test extends Entity {
  static _requestConfig = {
    app: 'open',
    domain: 'trace'
  }

  static _form = {}

  static _options = {}

  constructor (test) {
    super(test, { _tableField });
  }
}

export default Test;
