/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-05 08:09:29
 * @LastEditTime: 2022-01-13 10:29:05
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/model/collection.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import Test from './entity/_test';
import Oss from './entity/oss';
import AxqFeedback from './entity/axq/axqFeedback'
import Region from './entity/region';
import Member from './entity/member';
import Approval from './entity/approval';

export default {
  Test,
  Oss,
  AxqFeedback,
  Region,
  Member,
  Approval
};
