var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("div", { staticClass: "axq-input__prefix" }, [_vm._t("prefix")], 2),
      !_vm.textarea
        ? [
            _vm.password
              ? _c(
                  "div",
                  {
                    staticClass: "axq-input__inner",
                    class: {
                      "axq-input__inner--visible": _vm.isPasswdShow === true
                    }
                  },
                  [
                    _c("input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.placeholder,
                        "placeholder-class": _vm.phClass
                      },
                      domProps: { value: _vm.value },
                      on: { input: _vm.handleInput, blur: _vm.replaceInput }
                    }),
                    _vm.isPasswdShow
                      ? _c("span", { on: { input: _vm.handleInput } }, [
                          _vm._v(_vm._s(_vm.value.replace(/\S/g, "*")))
                        ])
                      : _vm._e()
                  ]
                )
              : _c("input", {
                  staticClass: "axq-input__inner",
                  attrs: {
                    type: _vm.type,
                    placeholder: _vm.placeholder,
                    "placeholder-class": _vm.phClass,
                    "placeholder-style": _vm.placeholderStyle,
                    disabled: _vm.disabled
                  },
                  domProps: { value: _vm.value },
                  on: { input: _vm.handleInput, blur: _vm.replaceInput }
                })
          ]
        : [
            _c("textarea", {
              staticClass: "axq-input__inner textarea",
              attrs: {
                autoHeight: _vm.autoHeight,
                placeholder: _vm.placeholder,
                "placeholder-class": _vm.phClass,
                "placeholder-style": _vm.placeholderStyle,
                disabled: _vm.disabled,
                maxlength: _vm.maxlength
              },
              domProps: { value: _vm.value },
              on: { input: _vm.handleInput, blur: _vm.replaceInput }
            })
          ],
      _c("div", { staticClass: "axq-input__suffix" }, [_vm._t("suffix")], 2),
      _vm.clearable && _vm.value
        ? _c("i", {
            staticClass: "iconfont icon-guanbi",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clear.apply(null, arguments)
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }