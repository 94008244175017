<template>
  <div class="apply-card" @click="toApplyProgress">
    <div class="apply-card-title">
      <img class="icon" :src="applyIcon | prefixOssUrl" />
      <span class="apply-type">{{ applyType }}</span>
      <div class="detail" v-if="!isCancel">
        <span class="detail-text">详情</span>
        <span class="detail-text">></span>
      </div>
    </div>
    <div class="apply-card-info">
      <div class="info-item">
        <div class="info-item-label">申请地区</div>
        <div
          class="info-item-value region"
        >{{ $_.get(data,'provinceName','')}}-{{ $_.get(data,'cityName','') }}-{{ $_.get(data,'districtName','') }}-{{ $_.get(data,'regionAllocateName','') }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">申请时间</div>
        <div class="info-item-value">{{ $_.get(data,'createTime')}}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">申请人</div>
        <div class="info-item-value">{{ $_.get(data,'applierName')}}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">联系电话</div>
        <div class="info-item-value">{{ $_.get(data,'applierMobile')}}</div>
      </div>
      <div class="info-item" v-if="data.typeId === 1 && data.type === 2">
        <div class="info-item-label">提交人</div>
        <div class="info-item-value">{{ $_.get(data,'creatorName')}}</div>
      </div>
      <div class="info-item" v-if="data.typeId === 2 ">
        <div class="info-item-label">区域拥有人</div>
        <div class="info-item-value">{{ $_.get(data,'regionAllocateAgentName') || '--'}}</div>
      </div>
      <div class="info-item" v-if="data.typeId === 2 ">
        <div class="info-item-label">联系电话</div>
        <div class="info-item-value">{{ $_.get(data,'regionAllocateAgentMobile')|| '--'}}</div>
      </div>
      <div
        class="info-item progress"
        :class="{
              cancel:data.status==4,
              reject:data.status==3,
              success:data.status==2,
            }"
      >
        <div class="info-item-label">进度</div>
        <div class="info-item-value">{{ curWorkFlow||'--' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressManager from '../@js/ProgressManager'

const ApprovalType = {
  REGION: 1,
  SHOP: 2
}
const ApprovalStatus = {
  AUDITING: 1,
  SUCCESS: 2,
  REJECT: 3,
  CANCEL: 4
}

export default {
  props: {
    data: Object
  },
  data () {
    return {}
  },
  computed: {
    isCancel () {
      return this.data.status == ApprovalStatus.CANCEL
    },
    applyIcon () {
      let res = 'apply/approval/shop.png'
      if (this.data.typeId == ApprovalType.REGION) {
        let isSelf = this.$_.get(this.data, 'type') == 1
        res = `apply/approval/${isSelf ? 'myself' : 'other'}.png`
      }
      return res
    },
    key () {
      return this.data.typeId == ApprovalType.SHOP ? 'approvalSubShop' : 'approvalSubRegionAllocateAgent'
    },
    applyType () {
      let res = ''
      if (this.data.typeId == ApprovalType.SHOP) {
        res += '店铺授权申请'
      } else {
        res += '区域授权'
        let isSelf = this.$_.get(this.data, 'type') === 1
        res += '-' + (isSelf ? '个人申请' : '他人申请')
      }
      return res
    },
    curWorkFlow () {
      let workFlowsName = this.data.workflowName
      let res = ''
      if (this.data.status == ApprovalStatus.REJECT) {
        res += workFlowsName + '审核不通过'
      } else if (this.data.status == ApprovalStatus.SUCCESS) {
        let typeName = ProgressManager.getTypeName(this.data)
        res += typeName + '审核通过'
      } else if (this.data.status == ApprovalStatus.CANCEL) {
        res = '已取消'
      } else {
        res += workFlowsName + '审核中'
      }
      return res
    }
  },
  methods: {
    toApplyProgress () {
      if (this.isCancel) return
      this.go(`/apply/approval/_id?id=${this.data.id}&type=${this.data.typeId}`)
    }
  }
}
</script>

<style lang="less" scoped>

.apply-card {
  width: 690px;
  background: #fff;
  border-radius: 20px;

  &-title {
    display: flex;
    align-items: center;
    padding: 0 0 0 24px;
    height: 90px;
    border-bottom: 2px solid #e6e6e6;

    .icon {
      width: 36px;
      height: 36px;
      background: #ccc;
    }

    .apply-type {
      flex: 1;
      margin-left: 27px;
      font-size: 36px;
      font-weight: bold;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .detail {
      display: flex;
      align-items: center;
      padding: 0 24px;

      &-text {
        color: #db187c;
        font-size: 30px;
      }
    }
  }

  &-info {
    padding: 24px;

    .info-item {
      display: flex;
      align-items: center;
      padding: 0 24px;
      height: 90px;
      background: #f8f8f8;
      border-bottom: 2px solid #e6e6e6;

      &-label {
        font-size: 30px;
        color: #999;
      }

      &-value {
        flex: 1;
        font-size: 30px;
        color: #333233;
        text-align: right;
      }

      .region {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &:first-of-type {
        border-radius: 10px 10px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 10px 10px;
        border-bottom: none;
      }

      &.progress {
        .info-item-value {
          color: #127eec;
        }

        &.reject .info-item-value {
          color: #ff9900;
        }

        &.success .info-item-value {
          color: #19be6b;
        }

        &.cancel .info-item-value {
          color: #ed4014;
        }
      }
    }
  }

  &:active {
    background: #eee;
  }
}
</style>